import * as React from "react";
import { RouteType } from "./types/Route";

//App
const HomePage = React.lazy(() => import("./containers/HomePage"));
const LogoutPage = React.lazy(() => import("./containers/LogoutPage"));
const ProfilePage = React.lazy(() => import("./containers/ProfilePage"));
const ProfilePageRelative = React.lazy(() => import("./containers/ProfilePageRelative"));
const ContentPage = React.lazy(() => import("./containers/ContentPage"));
const ErrorPage = React.lazy(() => import("./containers/ErrorPage"));
const MyNotesPage = React.lazy(() => import("./containers/notes/MyNotesPage"));
const MyNotesConcretePage = React.lazy(() => import("./containers/notes/MyNotesConcretePage"));
const MyNotesDailyPage = React.lazy(() => import("./containers/notes/MyNotesDailyPage"));
const LoginPage = React.lazy(() => import("./containers/auth/LoginPage"));
const RegisterPage = React.lazy(() => import("./containers/auth/RegisterPage"));
const RegisterRelativePage = React.lazy(() => import("./containers/auth/RelativeRegisterPage"));
const MyRelativesPage = React.lazy(() => import("./containers/MyRelativesPage"));
const MyRelativesChatPage = React.lazy(() => import("./containers/MyRelativesChatPage"));
const ForgotPasswordPage = React.lazy(() => import("./containers/auth/ForgotPasswordPage"));
const RegisterSuccessPage = React.lazy(() => import("./containers/auth/RegisterSuccessPage"));
const ResetPasswordPage = React.lazy(() => import("./containers/auth/ResetPasswordPage"));
const ResetPasswordSuccessPage = React.lazy(() => import("./containers/auth/ResetPasswordSuccessPage"));
const ClientForumPage = React.lazy(() => import("./containers/forum/client-forum/ClientForumPage"));
const ClientForumPostPage = React.lazy(() => import("./containers/forum/client-forum/ClientForumPostPage"));
const ClientForumCreatePostPage = React.lazy(() => import("./containers/forum/client-forum/ClientForumCreatePostPage"));
const ClientForumRequestAccessPage = React.lazy(() => import("./containers/forum/client-forum/ClientForumRequestAccessPage"));
const ClientForumCreateCommentPage = React.lazy(() => import("./containers/forum/client-forum/ClientForumCreateCommentPage"));
const RelativesForumPage = React.lazy(() => import("./containers/forum/relatives-forum/RelativesForumPage"));
const RelativesForumPostPage = React.lazy(() => import("./containers/forum/relatives-forum/RelativesForumPostPage"));
const RelativesForumCreatePostPage = React.lazy(() => import("./containers/forum/relatives-forum/RelativesForumCreatePostPage"));
const RelativesForumCreateCommentPage = React.lazy(() => import("./containers/forum/relatives-forum/RelativesForumCreateCommentPage"));
const NoteEditPage = React.lazy(() => import("./containers/notes/NoteEditPage"));
const MindfulnessPage = React.lazy(() => import("./containers/MindfulnessPage"));
const MotivationalVideosPage = React.lazy(() => import("./containers/MotivationalVideosPage"));
const ProfileSettingsPage = React.lazy(() => import("./containers/ProfileSettingsPage"));
const LudomaniSurveyPage = React.lazy(() => import("./containers/LudomaniSurveyPage"));

//Administration
const SettingsUserClientForumAccessPage = React.lazy(() => import("./containers/administration/SettingsUserClientForumAccessPage"));
// const MyClientsPage = React.lazy(() => import("./containers/administration/MyClientsPage"));

export const routes = {
  home: "/",

  //auth
  loginPage: "/auth/login",
  registerPage: "/auth/register",
  registerRelativePage: "/auth/registerRelative",
  registerSuccessPage: "/auth/register/success",
  forgotPasswordPage: "/auth/forgot",
  resetPasswordPage: "/auth/reset",
  resetPasswordSuccessPage: "/auth/reset/success",

  //logout
  logoutPage: "/auth/logout",

  //profile
  profilePage: "/profile",
  profilePageRelative: "/profilerel",
  profileSettingsPage: "/profileSettings",

  //Notes
  myNotes: "/notes",
  myNotes_Concrete: "/notesconcrete",
  myNotes_Daily: "/myNotes_Daily",
  noteEdit: "/notes/edit",

  //Relatives
  myRelatives: "/myrelatives",
  myRelativesChat: "/myrelativeschat",

  //Forum
  clientForumPage: "/forum",
  clientForumPostPage: "/forum/post",
  clientForumCreatePostPage: "/forum/post/create",
  clientForumRequestAccessPage: "/forum/request-access",
  clientForumCreateCommentPage: "/forum/comment/create",

  relativesForumPage: "/forumrel",
  relativesForumPostPage: "/forumrel/post",
  relativesForumCreatePostPage: "/forumrel/post/create",
  realtivesForumCreateCommentPage: "/forumrel/comment/create",

  //Mindfulness
  mindfulnessPage: "/mindfulness",

  //cms
  cmsPage: "/content/:reference",

  //Test ludomani
  ludomaniSurveyPage: "/ludomani-survey",

  //motivationsvideoer
  motivationalVideosPage: "/motivations-videoer",

  //Administration
  settingsUserClientForumAccessPage: "/admin/settings/user/clientForumAccess",
  myClientsPage: "/admin/clients",
};

export const Routes: RouteType = {
  home: {
    path: routes.home,
    exact: true,
    authorized: true,
    component: HomePage,
  },
  logoutPage: {
    path: routes.logoutPage,
    exact: true,
    authorized: true,
    component: LogoutPage
  },
  profilePage: {
    path: routes.profilePage,
    exact: true,
    authorized: true,
    component: ProfilePage,
  },
  profilePageRelative: {
    path: routes.profilePageRelative,
    exact: true,
    authorized: true,
    component: ProfilePageRelative,
  },
  profileSettingsPage: {
    path: routes.profileSettingsPage,
    exact: true,
    authorized: true,
    component: ProfileSettingsPage,
  },
  cmsPage: {
    path: routes.cmsPage,
    exact: true,
    authorized: true,
    component: ContentPage,
  },
  myNotes: {
    path: routes.myNotes,
    exact: true,
    authorized: true,
    component: MyNotesPage,
  },
  myNotesContete: {
    path: routes.myNotes_Concrete,
    exact: true,
    authorized: true,
    component: MyNotesConcretePage,
  },
  myNotesDaily: {
    path: routes.myNotes_Daily,
    exact: true,
    authorized: true,
    component: MyNotesDailyPage,
  },
  notesEdit: {
    path: routes.noteEdit,
    exact: true,
    authorized: true,
    component: NoteEditPage,
  },
  myRelativesPage: {
    path: routes.myRelatives,
    exact: true,
    authorized: true,
    component: MyRelativesPage,
  },
  myRelativesChatPage: {
    path: routes.myRelativesChat,
    exact: true,
    authorized: true,
    component: MyRelativesChatPage,
  },
  loginPage: {
    path: routes.loginPage,
    exact: true,
    component: LoginPage,
  },
  registerPage: {
    path: routes.registerPage,
    exact: true,
    component: RegisterPage,
  },
  registerRelativePage: {
    path: routes.registerRelativePage,
    exact: true,
    component: RegisterRelativePage
  },
  forgotPasswordPage: {
    path: routes.forgotPasswordPage,
    exact: true,
    component: ForgotPasswordPage,
  },
  registerSuccessPage: {
    path: routes.registerSuccessPage,
    exact: true,
    component: RegisterSuccessPage,
  },
  resetPasswordPage: {
    path: routes.resetPasswordPage,
    exact: true,
    component: ResetPasswordPage,
  },
  resetPasswordSuccessPage: {
    path: routes.resetPasswordSuccessPage,
    exact: true,
    component: ResetPasswordSuccessPage,
  },
  settingsUserClientForumAccessPage: {
    path: routes.settingsUserClientForumAccessPage,
    exact: true,
    authorized: true,
    component: SettingsUserClientForumAccessPage,
  },
  // myClientsPage: {
  //   path: routes.myClientsPage,
  //   exact: true,
  //   authorized: true,
  //   component: MyClientsPage,
  // },
  clientForumPage: {
    path: routes.clientForumPage,
    exact: true,
    authorized: true,
    component: ClientForumPage,
  },
  clientForumPostPage: {
    path: routes.clientForumPostPage,
    exact: true,
    authorized: true,
    component: ClientForumPostPage,
  },
  clientForumCreatePostPage: {
    path: routes.clientForumCreatePostPage,
    exact: true,
    authorized: true,
    component: ClientForumCreatePostPage,
  },
  clientForumRequestAccessPage: {
    path: routes.clientForumRequestAccessPage,
    exact: true,
    authorized: true,
    component: ClientForumRequestAccessPage,
  },
  clientForumCreateCommentPage: {
    path: routes.clientForumCreateCommentPage,
    exact: true,
    authorized: true,
    component: ClientForumCreateCommentPage,
  },
  relativesForumPage: {
    path: routes.relativesForumPage,
    exact: true,
    authorized: true,
    component: RelativesForumPage,
  },
  relativesForumPostPage: {
    path: routes.relativesForumPostPage,
    exact: true,
    authorized: true,
    component: RelativesForumPostPage,
  },
  relativesForumCreatePostPage: {
    path: routes.relativesForumCreatePostPage,
    exact: true,
    authorized: true,
    component: RelativesForumCreatePostPage,
  },
  relativesForumCreateCommentPage: {
    path: routes.realtivesForumCreateCommentPage,
    exact: true,
    authorized: true,
    component: RelativesForumCreateCommentPage,
  },
  mindfulnessPage: {
    path: routes.mindfulnessPage,
    exact: true,
    authorized: true,
    component: MindfulnessPage,
  },
  ludomaniSurveyPage: {
    path: routes.ludomaniSurveyPage,
    exact: true,
    authorized: true,
    component: LudomaniSurveyPage,
  },
  motivationalVideosPage: {
    path: routes.motivationalVideosPage,
    exact: true,
    authorized: true,
    component: MotivationalVideosPage,
  },
  notFound: {
    path: "*",
    exact: false,
    component: ErrorPage,
  },
  // Do NOT place new pages after "notFound"... Or face the never-ending error-page hell... You have been warned..
};
