import { AxiosResponse } from "axios";
import { TMoodRatingApiEntity } from "./User";

export enum SetMood {
  REQUEST = "SET_MOOD_REQUEST",
  SUCCESS = "SET_MOOD_SUCCESS",
  FAILED = "SET_MOOD_FAILED",
}
export type TSetMoodAction =
  | {
    type: SetMood.REQUEST;
  }
  | {
    type: SetMood.SUCCESS;
    payload: any;
  }
  | {
    type: SetMood.FAILED;
    payload: any;
  };

export enum SetLastMoodDialogShown {
  REQUEST = "SET_LAST_MOOD_DIALOG_SHOWN_REQUEST",
  SUCCESS = "SET_LAST_MOOD_DIALOG_SHOWN_SUCCESS",
  FAILED = "SET_LAST_MOOD_DIALOG_SHOWN_FAILED"
};
export type TSetLastMoodDialogShownAction =
  | {
    type: SetLastMoodDialogShown.REQUEST;
  }
  | {
    type: SetLastMoodDialogShown.SUCCESS;
    payload: any;
  }
  | {
    type: SetLastMoodDialogShown.FAILED;
    payload: any;
  };

export enum GetUserMoodRatings {
  REQUEST = "GET_USER_MOOD_RATINGS_REQUEST",
  SUCCESS = "GET_USER_MOOD_RATINGS_SUCCESS",
  FAILED = "GET_USER_MOOD_RATINGS_FAILED",
};
export type TGetUserMoodRatingsAction =
  | {
    type: GetUserMoodRatings.REQUEST;
  }
  | {
    type: GetUserMoodRatings.SUCCESS;
    payload: Array<TMoodRatingApiEntity>;
  }
  | {
    type: GetUserMoodRatings.FAILED;
    payload: any;
  };

export enum GetUserMoodRatingsByUserId {
  REQUEST = "GET_USER_MOOD_RATINGS_BY_USER_ID_REQUEST",
  SUCCESS = "GET_USER_MOOD_RATINGS_BY_USER_ID_SUCCESS",
  FAILED = "GET_USER_MOOD_RATINGS_BY_USER_ID_FAILED",
}
export type TGetUserMoodRatingsByUserIdAction = 
| {
  type: GetUserMoodRatingsByUserId.REQUEST;
}
| {
  type: GetUserMoodRatingsByUserId.SUCCESS;
  payload: Array<TMoodRatingApiEntity>;
}
| {
  type: GetUserMoodRatingsByUserId.FAILED;
  payload: any;
}