import {
  AddOrUpdateUserSetting,
  AddOrUpdateUserSettings,
  GetUserSettings,
  GetUserSettingsByUserId,
  TAddOrUpdateUserSettingAction,
  TAddOrUpdateUserSettingsAction,
  TGetUserSettingsAction,
  TGetUserSettingsByUserIdAction,
} from "../../types/UserSettings";
import { apiCall } from "./BaseAction";

export const getUserSettings = () => {
  return apiCall<TGetUserSettingsAction>(GetUserSettings, "GET", "/user-settings", true);
};
export const getUserSettingsByUserId = (user_id: number) => {
  return apiCall<TGetUserSettingsByUserIdAction>(
    GetUserSettingsByUserId,
    "GET",
    "/user-settings/by-id/" + user_id,
    true
  )
};
export type TAddOrUpdateUserSettingRequest = {
  setting_name: string;
  setting_value: string;
}
export const addOrUpdateUserSetting = (userSetting: TAddOrUpdateUserSettingRequest) => {
  return apiCall<TAddOrUpdateUserSettingAction>(
    AddOrUpdateUserSetting,
    "PUT",
    "/user-settings/single",
    true,
    userSetting
  );
}
export type TAddOrUpdateUserSettingsRequest = {
  userSettings: Array<TAddOrUpdateUserSettingRequest>;
}
export const addOrdUpdateUserSettings = (userSettings: TAddOrUpdateUserSettingsRequest) => {
  return apiCall<TAddOrUpdateUserSettingsAction>(
    AddOrUpdateUserSettings,
    "PUT",
    "/user-settings/multiple",
    true,
    userSettings
  );
}