export enum MenuType {
  MAIN = "main-menu",
  CRISIS = "crisis-menu",
}

export type TApiMenuEntityViewBag = {
  isHidden: string;
  cssClass: string;
  isExternal: string;
};

export enum ApiMenuEntityType {
  STATIC_PAGE = "static-page",
  URL = "url",
  HOLDER = "holder",
}

export enum ApiMenuEntityCode {
  SIMPLE = "",
  PHONE = "phone",
  HOLDER = "holder",
  PROFILE = "profile",
  NOTES = "my-notes",
  NOTES_CONCRETE = "my-concrete-notes",
  NOTES_DAILY = "my-daily-notes",
  MY_RELATIVES = "my-relatives",
  FORUM_CLIENT = "client-forum",
  FORUM_RELATIVES = "relatives-forum",
  LOGOUT = "logout",
  MINDFULNESS = "mindfulness",
  MOTIVATIONAL_VIDEOS = "motivational-videos",
  MY_CLIENTS = "my-clients",
  LUDOMANI_SURVEY = "ludomani-survey",
}

export interface TApiMenuEntity {
  title: string;
  route?: string;
  items?: Array<TApiMenuEntity>;
  notificationKey?: string;
  iconKey?: string;
  disabled?: string;
  external?: boolean;
}

export interface TMenuState {
  readonly mainMenu?: Array<TApiMenuEntity>;
  readonly crisisMenu?: Array<TApiMenuEntity>;
  readonly navbarMenuOpen?: boolean;
  readonly activeSubMenu?: Array<TApiMenuEntity>;
  readonly activeMenuType?: MenuType;
}

export const initialMenuState: TMenuState = {
  mainMenu: undefined,
};

export enum GetMainMenu {
  REQUEST = "GET_MAIN_MENU_REQUEST",
  SUCCESS = "GET_MAIN_MENU_SUCCESS",
  FAILED = "GET_MAIN_MENU_FAILED",
}

export type TGetMainMenuAction =
  | {
      type: GetMainMenu.REQUEST;
    }
  | {
      type: GetMainMenu.SUCCESS;
      payload: Array<TApiMenuEntity>;
    }
  | {
      type: GetMainMenu.FAILED;
      payload: Array<TApiMenuEntity>;
    };

export enum GetCrisisMenu {
  REQUEST = "GET_CRISIS_MENU_REQUEST",
  SUCCESS = "GET_CRISIS_MENU_SUCCESS",
  FAILED = "GET_CRISIS_MENU_FAILED",
}

export type TGetCrisisMenuAction =
  | {
      type: GetCrisisMenu.REQUEST;
    }
  | {
      type: GetCrisisMenu.SUCCESS;
      payload: Array<TApiMenuEntity>;
    }
  | {
      type: GetCrisisMenu.FAILED;
      payload: Array<TApiMenuEntity>;
    };

export enum SetMenuOpen {
  SET = "SET_MENU_OPEN",
}

export type TSetMenuOpenAction = {
  type: SetMenuOpen.SET;
  payload: boolean;
};

export enum SetSubMenuOpen {
  SET = "SET_SUB_MENU_OPEN",
}

export type TSetSubMenuOpenAction = {
  type: SetSubMenuOpen.SET;
  payload: Array<TApiMenuEntity>;
  menuType?: MenuType;
};

export type TMenuActions =
  | TGetMainMenuAction
  | TSetMenuOpenAction
  | TGetCrisisMenuAction
  | TSetSubMenuOpenAction;
