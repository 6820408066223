import {
  CreateRelativesForumPost,
  CreateRelativesForumPostComment,
  DeleteForumPostCommentRelatives,
  DeleteForumPostRelatives,
  GetForumPostRelatives,
  GetForumPostsRelatives,
  GetForumPostsRelativesPage,
  HasAnyNewMessagesRelativesForum,
  TCreateRelativesForumPostAction,
  TCreateRelativesForumPostCommentAction,
  TDeleteForumPostCommentRelativesAction,
  TDeleteForumPostRelativesAction,
  TGetForumPostRelativesAction,
  TGetForumPostsRelativesAction,
  TGetForumPostsRelativesPageAction,
  THasAnyNewMessagesRelativesForumAction,
} from "../../types/RelativeForum";
import { apiCall, silentApiCall } from "./BaseAction";
import { Dispatch } from "redux";


// Forum
export const getForumPosts_Relatives = () => {
  return apiCall<TGetForumPostsRelativesAction>(
    GetForumPostsRelatives,
    "GET",
    "/forum/posts-relatives",
    true
  );
};
export const getForumPosts_Relatives_Page = (pageUrl: string) => {
  return apiCall<TGetForumPostsRelativesPageAction>(
    GetForumPostsRelativesPage,
    "GET",
    pageUrl,
    true
  );
};
export type TCreateRelativesForumPostRequest = {
  title: string;
  content: string;
};
export const createRelativesForumPost = (data: TCreateRelativesForumPostRequest) => {
  return apiCall<TCreateRelativesForumPostAction>(
    CreateRelativesForumPost,
    "POST",
    "/forum/posts-relatives",
    true,
    data
  );
};
export type TCreateRelativesForumPostCommentRequest = {
  post_id: string;
  comment: string;
};
export const createRelativesForumPostComment = (data: TCreateRelativesForumPostCommentRequest) => {
  return apiCall<TCreateRelativesForumPostCommentAction>(
    CreateRelativesForumPostComment,
    "POST",
    "/forum/comments-relatives",
    true,
    data
  );
};
export const getForumPost_Relatives = (id: string) => {
  return apiCall<TGetForumPostRelativesAction>(
    GetForumPostRelatives,
    "GET",
    "/forum/posts-relatives/" + id,
    true
  );
};
export type TDeleteForumPostRelativesRequest = {
  post_id: number;
};
export const deleteForumPost_Relatives = (data: TDeleteForumPostRelativesRequest) => {
  return apiCall<TDeleteForumPostRelativesAction>(
    DeleteForumPostRelatives,
    "DELETE",
    "/forum/posts-relatives",
    true,
    data
  );
};
export type TDeleteForumPostCommentRelativesRequest = {
  comment_id: number;
}
export const deleteForumPostComment_Relatives = (data: TDeleteForumPostCommentRelativesRequest) => {
  return apiCall<TDeleteForumPostCommentRelativesAction>(
    DeleteForumPostCommentRelatives,
    "DELETE",
    "/forum/comments-relatives",
    true,
    data
  );
};
export const hasAnyNewMessagesRelativesForum = () => {
  return silentApiCall<THasAnyNewMessagesRelativesForumAction>(
    HasAnyNewMessagesRelativesForum,
    "GET",
    "/forum/get-new-relatives",
    true
  );
};
// \ Forum
