import { TNoteType } from "../store/actions/UserActions";
import { TGetUserMoodRatingsAction, TGetUserMoodRatingsByUserIdAction } from "./Mood";

export interface TApiUserEntity {
  activated_at: Date;
  created_at: Date;
  created_ip_address?: string;
  crisis_person_name?: string;
  crisis_person_phone?: string;
  deleted_at?: Date;
  email: string;
  id: number;
  is_activated: boolean;
  is_enabled: boolean;
  is_guest: number;
  is_superuser: number;
  last_gambled: Date;
  last_ip_address?: string;
  last_login?: Date;
  last_mood_dialog_shown?: Date;
  last_seen?: Date;
  money_spend_per_period?: number;
  money_spend_period: "week" | "month";
  name?: string;
  permissions?: any;
  surname?: string;
  time_spent_per_period?: number;
  updated_at: Date;
  alias?: string;
  role_id: number;
  username: string;
  user_settings?: TApiUserSettingsEntity[];
};

export interface TApiUserSettingsEntity {
  user_id: number;
  setting_name: string;
  setting_value: number;
};

export interface TApiUserUserEntity {
  accepted: number;
  family_member: { id: number, name: string, surname: string };
  family_member_id: number;
  new_message_count: number;
  user: { id: number, name: string, surname: string };
  user_id: number;
};

export type TMotivatingImageApiEntity = {
  created_at: Date;
  deleted_at?: Date;
  id: number;
  image: TImageApiEntity;
  sort_order: number;
  text?: string;
  updated_at: Date;
  user_id: number;
};

export type TMyNoteApiEntity = {
  created_at: Date;
  id: number;
  note_type: number;
  parent_url: string;
  text: string;
  updated_at: Date;
  user_id: number;
};

export type TMyConcreteNoteApiEntity = {
  created_at: Date;
  id: number;
  text: string;
  type: string;
  updated_at: Date;
  user_id: number;
};

export type TMyDailyNoteApiEntity = {
  created_at: Date;
  id: number;
  gambling_urge: number;
  mood_rating_id: number;
  mood_rate: number;
  text: string;
  updated_at: Date;
  user_id: number;
};

export type TImageApiEntity = {
  content_type: string;
  created_at: Date;
  description?: string;
  disk_name: string;
  extension: string;
  field: string;
  file_name: string;
  file_size: number;
  id: number;
  path: string;
  sort_order: number;
  title?: string;
  updated_at: Date;
};

export type TApiFamilyInvitationEntity = {
  email: string;
  invited_by: number;
};

export type TApiRelativeChatMessageEntity = {
  created_at: Date;
  id: number;
  message: string;
  recipient_id: number;
  sender_id: number;
  updated_at: Date;
  uuid: string;
};

export type TApiPaginatorEntity<T> = {
  current_page: number;
  data: Array<T>;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: 25;
  prev_page_url: string;
  to: number;
  total: number;
};

export type TTreaterApiEntity = {
  created_at: Date;
  id: number;
  updated_at: Date;
  department_id: number;
  user: TApiUserEntity;
};

export type TApiTreaterDepartmentEntity = {
  created_at: Date;
  id: number;
  name: string;
  updated_at: Date;
};

export type TUseraliasValidationResApiEntity = {
  ok: boolean;
  message?: string;
};

/**
 * Generic type T is intended for the content of the TApiPaginatorEntity.
 */
export type TChatMessageThreadSingleRecipientEntity<T> = {
  chatMessages: TApiPaginatorEntity<T>;
  recipient: TApiUserEntity;
};

export type TMoodRatingApiEntity = {
  created_at: Date;
  gambling_urge: number;
  id: number;
  rate: number;
  updated_at: Date;
  user_id: number;
};

export type TApiUserRoleEntity = {
  created_at: Date;
  id: number;
  role: string;
  update_at: Date;
};

export interface TUserState {
  readonly badMoodReference?: string;
  readonly chatMessageCountAllRelatives?: number;
  readonly chatThreadMessages?: TChatMessageThreadSingleRecipientEntity<TApiRelativeChatMessageEntity>;
  readonly chatThreadRecipientUserId?: number;
  readonly data?: TApiUserEntity; // readonly user?: TApiUserEntity
  readonly hasPendingInvitations?: number;
  readonly loading?: boolean;
  readonly moodRatings?: Array<TMoodRatingApiEntity>;
  readonly motivatingImages?: Array<TMotivatingImageApiEntity>;
  readonly myClients?: Array<TApiUserEntity>;
  readonly myNotes?: Array<TMyNoteApiEntity>;
  readonly myNotesConcrete?: Array<TMyConcreteNoteApiEntity>;
  readonly myNotesDaily?: Array<TMyDailyNoteApiEntity>;
  readonly myNotesDaily_ViewMode?: number;
  readonly myNotesSelectedNote?: TMyNoteApiEntity;
  readonly myNotesSelectedNoteType?: TNoteType;
  readonly myRelatives?: Array<TApiUserUserEntity>;
  readonly myRelativeInvitations?: Array<TApiFamilyInvitationEntity>;
  readonly noteSaved?: boolean;
  readonly tempNoteText?: string;
  readonly treaterDepartments?: Array<TApiTreaterDepartmentEntity>;
  readonly treaterList?: Array<TTreaterApiEntity>;
  readonly userRoles?: Array<TApiUserRoleEntity>;
};

export const initialUserState: TUserState = {
  data: undefined,
  badMoodReference: undefined,
  chatMessageCountAllRelatives: undefined,
  chatThreadMessages: undefined,
  chatThreadRecipientUserId: undefined,
  hasPendingInvitations: undefined,
  loading: undefined,
  moodRatings: undefined,
  motivatingImages: undefined,
  myClients: undefined,
  myNotes: undefined,
  myNotesConcrete: undefined,
  myNotesDaily: undefined,
  myNotesDaily_ViewMode: undefined,
  myNotesSelectedNote: undefined,
  myNotesSelectedNoteType: undefined,
  myRelatives: undefined,
  myRelativeInvitations: undefined,
  noteSaved: undefined,
  tempNoteText: undefined,
  treaterDepartments: undefined,
  treaterList: undefined,
  userRoles: undefined,
};

// Users
export enum GetCurrentUser {
  REQUEST = "GET_CURRENT_USER_REQUEST",
  SUCCESS = "GET_CURRENT_USER_SUCCESS",
  FAILED = "GET_CURRENT_USER_FAILED",
};
export type TGetCurrentUserAction =
  | {
    type: GetCurrentUser.REQUEST;
  }
  | {
    type: GetCurrentUser.SUCCESS;
    payload: { user: TApiUserEntity };
  }
  | {
    type: GetCurrentUser.FAILED;
    payload: { user: TApiUserEntity };
  };

export enum UpdateUserProfile {
  REQUEST = "UPDATE_USER_PROFILE_REQUEST",
  SUCCESS = "UPDATE_USER_PROFILE_SUCCESS",
  FAILED = "UPDATE_USER_PROFILE_FAILED",
};
export type TUpdateUserProfileAction =
  | {
    type: UpdateUserProfile.REQUEST;
  }
  | {
    type: UpdateUserProfile.SUCCESS;
    payload: TApiUserEntity;
  }
  | {
    type: UpdateUserProfile.FAILED;
    payload: TApiUserEntity;
  };

export enum UpdateUserProfileSettings {
  REQUEST = "UPDATE_USER_PROFILE_SETTINGS_REQUEST",
  SUCCESS = "UPDATE_USER_PROFILE_SETTINGS_SUCCESS",
  FAILED = "UPDATE_USER_PROFILE_SETTINGS_FAILED",
};
export type TUpdateUserProfileSettingsAction =
  | {
    type: UpdateUserProfileSettings.REQUEST;
  }
  | {
    type: UpdateUserProfileSettings.SUCCESS;
    payload: TApiUserEntity;
  }
  | {
    type: UpdateUserProfileSettings.FAILED;
    payload: TApiUserEntity;
  }

export enum GetUserById {
  REQUEST = "GET_USER_BY_ID_REQUEST",
  SUCCESS = "GET_USER_BY_ID_SUCCESS",
  FAILED = "GET_USER_BY_ID_FAILED",
};
export type TGetUserByIdAction =
  | {
    type: GetUserById.REQUEST;
  }
  | {
    type: GetUserById.SUCCESS;
    payload: TApiUserEntity;
  }
  | {
    type: GetUserById.FAILED;
    payload: any;
  };

export enum AssignAccessToClientForum {
  REQUEST = "ASSIGN_ACCESS_TO_CLIENT_FORUM_REQUEST",
  SUCCESS = "ASSIGN_ACCESS_TO_CLIENT_FORUM_SUCCESS",
  FAILED = "ASSIGN_ACCESS_TO_CLIENT_FORUM_FAILED",
};
export type TAssignAccessToClientForumAction =
  | {
    type: AssignAccessToClientForum.REQUEST,
  }
  | {
    type: AssignAccessToClientForum.SUCCESS,
    payload: any,
  }
  | {
    type: AssignAccessToClientForum.FAILED,
    payload: any,
  }

export enum RejectClientForumAccess {
  REQUEST = "REJECT_CLIENT_FORUM_ACCESS_REQUEST",
  SUCCESS = "REJECT_CLIENT_FORUM_ACCESS_SUCCESS",
  FAILED = "REJECT_CLIENT_FORUM_ACCESS_FAILED",
}
export type TRejectClientForumAccessAction =
  | {
    type: RejectClientForumAccess.REQUEST;
  }
  | {
    type: RejectClientForumAccess.SUCCESS;
    payload: any;
  }
  | {
    type: RejectClientForumAccess.FAILED;
    payload: any;
  }

export enum AddUserTreaterRelation {
  REQUEST = "ADD_USER_TREATER_RELATION_REQUEST",
  SUCCESS = "ADD_USER_TREATER_RELATION_SUCCESS",
  FAILED = "ADD_USER_TREATER_RELATION_FAILED",
}
export type TAddUserTreaterRelationAction =
  | {
    type: AddUserTreaterRelation.REQUEST;
  }
  | {
    type: AddUserTreaterRelation.SUCCESS;
    payload: any;
  }
  | {
    type: AddUserTreaterRelation.FAILED;
    payload: any;
  }

export enum ValidateUseralias {
  REQUEST = "VALIDATE_USERALIAS_REQUEST",
  SUCCESS = "VALIDATE_USERALIAS_SUCCESS",
  FAILED = "VALIDATE_USERALIAS_FAILED",
}
export type TValidateUseraliasAction =
  | {
    type: ValidateUseralias.REQUEST;
  }
  | {
    type: ValidateUseralias.SUCCESS;
    payload: boolean;
  }
  | {
    type: ValidateUseralias.FAILED;
    payload: any;
  }

export enum GetUserRoles {
  REQUEST = "GET_USER_ROLES_REQUEST",
  SUCCESS = "GET_USER_ROLES_SUCCESS",
  FAILED = "GET_USER_ROLES_FAILED"
};
export type TGetUserRolesAction =
  | {
    type: GetUserRoles.REQUEST;
  }
  | {
    type: GetUserRoles.SUCCESS;
    payload: Array<TApiUserRoleEntity>;
  }
  | {
    type: GetUserRoles.FAILED;
    payload: any;
  }

export enum DeleteUser {
  REQUEST = "DELETE_USER_REQUEST",
  SUCCESS = "DELETE_USER_SUCCESS",
  FAILED = "DELETE_USER_FAILED"
};
export type TDeleteUserAction =
  | {
    type: DeleteUser.REQUEST;
  }
  | {
    type: DeleteUser.SUCCESS;
  }
  | {
    type: DeleteUser.FAILED;
  }
// \Users



// My Notes
export enum SaveMyNote {
  REQUEST = "SAVE_MY_NOTE_REQUEST",
  SUCCESS = "SAVE_MY_NOTE_SUCCESS",
  FAILED = "SAVE_MY_NOTE_FAILED",
}
export type TSaveMyNoteAction =
  | {
    type: SaveMyNote.REQUEST;
  }
  | {
    type: SaveMyNote.SUCCESS;
    payload: any;
  }
  | {
    type: SaveMyNote.FAILED;
    payload: any;
  };

export enum SaveDailyNote {
  REQUEST = "SAVE_DAILY_NOTE_REQUEST",
  SUCCESS = "SAVE_DAILY_NOTE_SUCCESS",
  FAILED = "SAVE_DAILY_NOTE_FAILED",
}
export type TSaveDailyNoteAction =
  | {
    type: SaveDailyNote.REQUEST;
  }
  | {
    type: SaveDailyNote.SUCCESS;
    payload: any;
  }
  | {
    type: SaveDailyNote.FAILED;
    payload: any;
  }

export enum SaveFreeNote {
  REQUEST = "SAVE_FREE_NOTE_REQUEST",
  SUCCESS = "SAVE_FREE_NOTE_SUCCESS",
  FAILED = "SAVE_FREE_NOTE_FAILED",
}
export type TSaveFreeNoteAction =
  | {
    type: SaveFreeNote.REQUEST;
  }
  | {
    type: SaveFreeNote.SUCCESS;
    payload: any;
  }
  | {
    type: SaveFreeNote.FAILED;
    payload: any;
  }

export enum SaveConcreteNote {
  REQUEST = "SAVE_CONCRETE_NOTE_REQUEST",
  SUCCESS = "SAVE_CONCRETE_NOTE_SUCCESS",
  FAILED = "SAVE_CONCRETE_NOTE_FAILED",
}
export type TSaveConcreteNoteAction =
  | {
    type: SaveConcreteNote.REQUEST;
  }
  | {
    type: SaveConcreteNote.SUCCESS;
    payload: any;
  }
  | {
    type: SaveConcreteNote.FAILED;
    payload: any;
  }

export enum GetMyNotes {
  REQUEST = "GET_MY_NOTES_REQUEST",
  SUCCESS = "GET_MY_NOTES_SUCCESS",
  FAILED = "GET_MY_NOTES_FAILED",
}
export type TGetMyNotesAction =
  | {
    type: GetMyNotes.REQUEST;
  }
  | {
    type: GetMyNotes.SUCCESS;
    payload: Array<TMyNoteApiEntity>;
  }
  | {
    type: GetMyNotes.FAILED;
    payload: any;
  };

export enum GetConcreteNotes {
  REQUEST = "GET_CONCRETE_NOTES_REQUEST",
  SUCCESS = "GET_CONCRETE_NOTES_SUCCESS",
  FAILED = "GET_CONCRETE_NOTES_FAILED",
}
export type TGetConcreteNotesAction =
  | {
    type: GetConcreteNotes.REQUEST;
  }
  | {
    type: GetConcreteNotes.SUCCESS;
    payload: Array<TMyConcreteNoteApiEntity>;
  }
  | {
    type: GetConcreteNotes.FAILED;
    payload: Array<any>;
  }

export enum GetMyDailyNotes {
  REQUEST = "GET_MY_DAILY_NOTES_REQUEST",
  SUCCESS = "GET_MY_DAILY_NOTES_SUCCESS",
  FAILED = "GET_MY_DAILY_NOTES_FAILED",
}
export type TGetMyDailyNotesAction =
  | {
    type: GetMyDailyNotes.REQUEST;
  }
  | {
    type: GetMyDailyNotes.SUCCESS;
    payload: Array<TMyDailyNoteApiEntity>;
  }
  | {
    type: GetMyDailyNotes.FAILED;
    payload: Array<any>;
  }

export enum CopyNoteText {
  SET = "COPY_NOTE_TEXT",
}
export type TCopyNoteTextAction = {
  type: CopyNoteText.SET;
  payload: string;
};

export enum DeleteMyNote {
  REQUEST = "DELETE_MY_NOTE_REQUEST",
  SUCCESS = "DELETE_MY_NOTE_SUCCESS",
  FAILED = "DELETE_MY_NOTE_FAILED",
}
export type TDeleteMyNoteAction =
  | {
    type: DeleteMyNote.REQUEST;
  }
  | {
    type: DeleteMyNote.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteMyNote.FAILED;
    payload: any;
  };

export enum DeleteMyConcreteNote {
  REQUEST = "DELETE_MY_CONCRETE_NOTE_REQUEST",
  SUCCESS = "DELETE_MY_CONCRETE_NOTE_SUCCESS",
  FAILED = "DELETE_MY_CONCRETE_NOTE_FAILED",
}
export type TDeleteMyConcreteNoteAction =
  | {
    type: DeleteMyConcreteNote.REQUEST;
  }
  | {
    type: DeleteMyConcreteNote.SUCCESS;
    payload: Array<TMyConcreteNoteApiEntity>;
  }
  | {
    type: DeleteMyConcreteNote.FAILED;
    payload: Array<any>;
  }

export enum DeleteMyDailyNote {
  REQUEST = "DELETE_MY_DAILY_NOTE_REQUEST",
  SUCCESS = "DELETE_MY_DAILY_NOTE_SUCCESS",
  FAILED = "DELETE_MY_DAILY_NOTE_FAILED",
}
export type TDeleteMyDailyNoteAction =
  | {
    type: DeleteMyDailyNote.REQUEST;
  }
  | {
    type: DeleteMyDailyNote.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteMyDailyNote.FAILED;
    payload: any;
  }

export enum SwitchMyNotesDailyViewMode {
  SET = "SWITCHMYNOTESDAILYVIEWMODE_SET",
}
export type TSwitchMyNotesDailyViewModeAction =
  | {
    type: SwitchMyNotesDailyViewMode.SET;
    payload: number;
  }

export enum GetNote {
  REQUEST = "GET_NOTE_REQUEST",
  SUCCESS = "GET_NOTE_SUCCESS",
  FAILED = "GET_NOTE_FAILED",
}
export type TGetNoteAction =
  | {
    type: GetNote.REQUEST;
  }
  | {
    type: GetNote.SUCCESS;
    payload: TMyNoteApiEntity;
  }
  | {
    type: GetNote.FAILED;
    payload: any;
  }

export enum SetSelectedNoteType {
  SET = "SET_SELECTED_NOTE_TYPE_SET",
}
export type TSetSelectedNoteTypeAction =
  | {
    type: SetSelectedNoteType.SET;
    payload: TNoteType;
  }

export enum UpdateNoteText {
  REQUEST = "UODATE_NOTE_TEXT_REQUEST",
  SUCCESS = "UODATE_NOTE_TEXT_SUCCESS",
  FAILED = "UODATE_NOTE_TEXT_FAILED",
}
export type TUpdateNoteTextAction =
  | {
    type: UpdateNoteText.REQUEST;
  }
  | {
    type: UpdateNoteText.SUCCESS;
    payload: any;
  }
  | {
    type: UpdateNoteText.FAILED;
    payload: any;
  }
// My Notes



export enum SetMood {
  REQUEST = "SET_MOOD_REQUEST",
  SUCCESS = "SET_MOOD_SUCCESS",
  FAILED = "SET_MOOD_FAILED",
}

export type TSetMoodAction =
  | {
    type: SetMood.REQUEST;
  }
  | {
    type: SetMood.SUCCESS;
    payload: {
      "redirect-reference"?: string;
    };
  }
  | {
    type: SetMood.FAILED;
    payload: {
      "redirect-reference"?: string;
    };
  };

export enum UploadMotivationalImage {
  REQUEST = "UPLOAD_MOTIVATIONAL_IMAGE_REQUEST",
  SUCCESS = "UPLOAD_MOTIVATIONAL_IMAGE_SUCCESS",
  FAILED = "UPLOAD_MOTIVATIONAL_IMAGE_FAILED",
}

export type TUploadMotivationalImageAction =
  | {
    type: UploadMotivationalImage.REQUEST;
  }
  | {
    type: UploadMotivationalImage.SUCCESS;
    payload: any;
  }
  | {
    type: UploadMotivationalImage.FAILED;
    payload: any;
  };

export enum GetMotivatingImages {
  REQUEST = "GET_MOTIVATING_IMAGES_REQUEST",
  SUCCESS = "GET_MOTIVATING_IMAGES_SUCCESS",
  FAILED = "GET_MOTIVATING_IMAGES_FAILED",
}

export type TGetMotivatingImagesAction =
  | {
    type: GetMotivatingImages.REQUEST;
  }
  | {
    type: GetMotivatingImages.SUCCESS;
    payload: Array<TMotivatingImageApiEntity>;
  }
  | {
    type: GetMotivatingImages.FAILED;
    payload: Array<TMotivatingImageApiEntity>;
  };

export enum GetAllClients {
  REQUEST = "GET_CLIENTS_REQUEST",
  SUCCESS = "GET_CLIENTS_SUCCESS",
  FAILED = "GET_CLIENTS_FAILED",
}

export type TGetAllClientsAction =
  | {
    type: GetAllClients.REQUEST;
  }
  | {
    type: GetAllClients.SUCCESS;
    payload: Array<TApiUserEntity>;
  }
  | {
    type: GetAllClients.FAILED;
  };

export enum DeleteMotivatingImage {
  REQUEST = "DELETE_MOTIVATING_IMAGE_REQUEST",
  SUCCESS = "DELETE_MOTIVATING_IMAGE_SUCCESS",
  FAILED = "DELETE_MOTIVATING_IMAGE_FAILED",
}

export type TDeleteMotivatingImageAction =
  | {
    type: DeleteMotivatingImage.REQUEST;
  }
  | {
    type: DeleteMotivatingImage.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteMotivatingImage.FAILED;
    payload: any;
  };



// My Relatives
export enum AddMyRelative {
  REQUEST = "ADD_MY_RELATIVE_REQUEST",
  SUCCESS = "ADD_MY_RELATIVE_SUCCESS",
  FAILED = "ADD_MY_RELATIVE_FAILED",
}
export type TAddMyRelativeAction =
  | {
    type: AddMyRelative.REQUEST;
  }
  | {
    type: AddMyRelative.SUCCESS;
    payload: any;
  }
  | {
    type: AddMyRelative.FAILED;
    payload: any;
  }

export enum DeleteMyRelative {
  REQUEST = "DELETE_MY_RELATIVE_REQUEST",
  SUCCESS = "DELETE_MY_RELATIVE_SUCCESS",
  FAILED = "DELETE_MY_RELATIVE_FAILED",
}
export type TDeleteMyRelativeAction =
  | {
    type: DeleteMyRelative.REQUEST;
  }
  | {
    type: DeleteMyRelative.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteMyRelative.FAILED;
    payload: any;
  };

export enum DeleteMyRelativeInvitation {
  REQUEST = "DELETE_MY_RELATIVE_INVITATION_REQUEST",
  SUCCESS = "DELETE_MY_RELATIVE_INVITATION_SUCCESS",
  FAILED = "DELETE_MY_RELATIVE_INVITATION_FAILED"
}
export type TDeleteMyRelativeInvitationAction =
  | {
    type: DeleteMyRelativeInvitation.REQUEST;
  }
  | {
    type: DeleteMyRelativeInvitation.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteMyRelativeInvitation.FAILED;
    payload: any;
  }

export enum GetMyRelatives {
  REQUEST = "GET_MY_RELATIVES_REQUEST",
  SUCCESS = "GET_MY_RELATIVES_SUCCESS",
  FAILED = "GET_MY_RELATIVES_FAILED",
}
export type TGetMyRelativesAction =
  | {
    type: GetMyRelatives.REQUEST;
  }
  | {
    type: GetMyRelatives.SUCCESS;
    payload: Array<TApiUserUserEntity>;
  }
  | {
    type: GetMyRelatives.FAILED;
    payload: any;
  };

export enum AcceptInvitationExistingUser {
  REQUEST = "ACCEPT_INVITATION_EXISTING_USER_REQUEST",
  SUCCESS = "ACCEPT_INVITATION_EXISTING_USER_SUCCESS",
  FAILED = "ACCEPT_INVITATION_EXISTING_USER_FAILED",
}
export type TAcceptInvitationExistingUserAction =
  | {
    type: AcceptInvitationExistingUser.REQUEST;
  }
  | {
    type: AcceptInvitationExistingUser.SUCCESS;
    payload: any;
  }
  | {
    type: AcceptInvitationExistingUser.FAILED;
    payload: any;
  }

export enum HasPendingInvitations {
  REQUEST = "HAS_PENDING_INVITATIONS_REQUEST",
  SUCCESS = "HAS_PENDING_INVITATIONS_SUCCESS",
  FAILED = "HAS_PENDING_INVITATIONS_FAILED",
}
export type THasPendingInvitationsAction =
  | {
    type: HasPendingInvitations.REQUEST;
  }
  | {
    type: HasPendingInvitations.SUCCESS;
    payload: number;
  }
  | {
    type: HasPendingInvitations.FAILED;
    payload: any;
  }

export enum GetMyRelativeInvitations {
  REQUEST = "GET_RELATIVE_INVITATIONS_REQUEST",
  SUCCESS = "GET_RELATIVE_INVITATIONS_SUCCESS",
  FAILED = "GET_RELATIVE_INVITATIONS_FAILED"
}
export type TGetMyRelativeInvitationsAction =
  | {
    type: GetMyRelativeInvitations.REQUEST;
  }
  | {
    type: GetMyRelativeInvitations.SUCCESS;
    payload: any;
  }
  | {
    type: GetMyRelativeInvitations.FAILED;
    payload: any;
  }

export enum GetFamilyInvitationEmail {
  REQUEST = "GET_FAMILY_INVITATION_EMAIL_REQUEST",
  SUCCESS = "GET_FAMILY_INVITATION_EMAIL_SUCCESS",
  FAILED = "GET_FAMILY_INVITATION_EMAIL_FAIELD"
}
export type TGetFamilyInvitationEmailAction =
  | {
    type: GetFamilyInvitationEmail.REQUEST;
  }
  | {
    type: GetFamilyInvitationEmail.SUCCESS;
    payload: string;
  }
  | {
    type: GetFamilyInvitationEmail.FAILED;
    payload: any;
  }

export enum AcceptInvitationNewUser {
  REQUEST = "ACCEPT_INVITATION_NEW_USER_REQUEST",
  SUCCESS = "ACCEPT_INVITATION_NEW_USER_SUCCESS",
  FAILED = "ACCEPT_INVITATION_NEW_USER_FAILED"
}
export type TAcceptInvitationNewUserAction =
  | {
    type: AcceptInvitationNewUser.REQUEST;
  }
  | {
    type: AcceptInvitationNewUser.SUCCESS;
    payload: any;
  }
  | {
    type: AcceptInvitationNewUser.FAILED;
    payload: any;
  }
// / My Relatives


// Chat
export enum UpdateChatThreadRecipientUserId {
  SET = "Update_Chat_Thread_Recipient_User_Id"
}
export type TUpdateChatThreadRecipientUserIdAction = {
  type: UpdateChatThreadRecipientUserId.SET;
  payload: number;
}

export enum GetRelativeChatMessages {
  REQUEST = "GET_CHAT_MESSAGES_REQUEST",
  SUCCESS = "GET_CHAT_MESSAGES_SUCCESS",
  FAILED = "GET_CHAT_MESSAGES_FAILED",
}
export type TGetRelativeChatMessagesAction =
  | {
    type: GetRelativeChatMessages.REQUEST;
  }
  | {
    type: GetRelativeChatMessages.SUCCESS;
    payload: TChatMessageThreadSingleRecipientEntity<TApiRelativeChatMessageEntity>;
  }
  | {
    type: GetRelativeChatMessages.FAILED;
    payload: any;
  }

export enum SendMyRelaiveChatMessage {
  REQUEST = "SEND_MY_RELATIVE_CHAT_MESSAGE_REQUEST",
  SUCCESS = "SEND_MY_RELATIVE_CHAT_MESSAGE_SUCCESS",
  FAILED = "SEND_MY_RELATIVE_CHAT_MESSAGE_FAILED",
}
export type TSendMyRelaiveChatMessageAction =
  | {
    type: SendMyRelaiveChatMessage.REQUEST;
  }
  | {
    type: SendMyRelaiveChatMessage.SUCCESS;
    payload: any;
  }
  | {
    type: SendMyRelaiveChatMessage.FAILED;
    payload: any;
  }

export enum CheckForNewChatMessages {
  SILENTREQUEST = "CHECK_FOR_NEW_CHAT_MESSAGE_SILENTREQUEST",
  SILENTSUCCESS = "CHECK_FOR_NEW_CHAT_MESSAGE_SILENTSUCCESS",
  SILENTFAIELD = "CHECK_FOR_NEW_CHAT_MESSAGE_SILENTFAILED",
}
export type TCheckForNewChatMessagesAction =
  | {
    type: CheckForNewChatMessages.SILENTREQUEST;
  }
  | {
    type: CheckForNewChatMessages.SILENTSUCCESS;
    payload: any;
  }
  | {
    type: CheckForNewChatMessages.SILENTFAIELD;
    payload: any;
  }

export enum GetNewMsgForAnyRelative {
  SILENTREQUEST = "GET_NEW_MSG_FOR_ANY_RELATIVE_SILENTREQUEST",
  SILENTSUCCESS = "GET_NEW_MSG_FOR_ANY_RELATIVE_SILENTSUCCESS",
  SILENTFAILED = "GET_NEW_MSG_FOR_ANY_RELATIVE_SILENTFAILED",
}
export type TGetNewMsgForAnyRelativeAction =
  | {
    type: GetNewMsgForAnyRelative.SILENTREQUEST;
  }
  | {
    type: GetNewMsgForAnyRelative.SILENTSUCCESS;
    payload: any;
  }
  | {
    type: GetNewMsgForAnyRelative.SILENTFAILED;
    payload: any;
  }

export enum NewMessagesCountForRelative {
  REQUEST = "NEW_MESSAGE_COUNT_FOR_RELATIVE_REQUEST",
  SUCCESS = "NEW_MESSAGE_COUNT_FOR_RELATIVE_SUCCESS",
  FAILED = "NEW_MESSAGE_COUNT_FOR_RELATIVE_FAILED",
}
export type TNewMessagesCountForRelativeAction =
  | {
    type: NewMessagesCountForRelative.REQUEST;
  }
  | {
    type: NewMessagesCountForRelative.SUCCESS;
    payload: any;
  }
  | {
    type: NewMessagesCountForRelative.FAILED;
    payload: any;
  }
// \Chat


// Treaters
export enum GetTreaters {
  REQUEST = "GET_TREATERS_REQUEST",
  SUCCESS = "GET_TREATERS_SUCCESS",
  FAILED = "GET_TREATERS_FAILED",
}
export type TGetTreatersAction =
  | {
    type: GetTreaters.REQUEST;
  }
  | {
    type: GetTreaters.SUCCESS;
    payload: Array<TTreaterApiEntity>;
  }
  | {
    type: GetTreaters.FAILED;
    payload: any;
  }

export enum GetTreaterDeparments {
  REQUEST = "GET_TREATER_DEPARTMENTS_REQUEST",
  SUCCESS = "GET_TREATER_DEPARTMENTS_SUCCESS",
  FAILED = "GET_TREATER_DEPARTMENTS_FAILED",
}
export type TGetTreaterDeparmentsAction =
  | {
    type: GetTreaterDeparments.REQUEST;
  }
  | {
    type: GetTreaterDeparments.SUCCESS;
    payload: Array<TApiTreaterDepartmentEntity>;
  }
  | {
    type: GetTreaterDeparments.FAILED;
    payload: any;
  }
// \Treaters


// Gambling Free Calculator
export enum GetGamblingFreeCalculatorRelatedUser {
  REQUEST = "GET_GAMBLING_FREE_CALCULATOR_RELATED_USER_REQUEST",
  SUCCESS = "GET_GAMBLING_FREE_CALCULATOR_RELATED_USER_SUCCESS",
  FAILED = "GET_GAMBLING_FREE_CALCULATOR_RELATED_USER_FAILED",
};
export type TGetGamblingFreeCalculatorRelatedUserAction =
  | {
    type: GetGamblingFreeCalculatorRelatedUser.REQUEST;
  }
  | {
    type: GetGamblingFreeCalculatorRelatedUser.SUCCESS;
    payload: TApiUserEntity;
  }
  | {
    type: GetGamblingFreeCalculatorRelatedUser.FAILED;
    payload: any;
  }
// \Gambling Free Calculator


// Logout
export enum ResetUserStateToInitial {
  SET = "RESET_USER_STATE_TO_INITIAL_SET"
}
export type TResetUserStateToInitialAction =
  | {
    type: ResetUserStateToInitial.SET,
  }
// \Logout


export type TUserActions =
  | TGetCurrentUserAction
  | TSetMoodAction
  | TUpdateUserProfileAction
  | TCopyNoteTextAction
  | TSaveMyNoteAction
  | TUploadMotivationalImageAction
  | TGetMotivatingImagesAction
  | TDeleteMotivatingImageAction
  | TGetMyNotesAction
  | TDeleteMyNoteAction
  | TDeleteMyRelativeAction
  | TGetMyRelativesAction
  | TAddMyRelativeAction
  | TDeleteMyRelativeAction
  | THasPendingInvitationsAction
  | TGetMyRelativeInvitationsAction
  | TDeleteMyRelativeInvitationAction
  | TGetFamilyInvitationEmailAction
  | TAcceptInvitationExistingUserAction
  | TSaveFreeNoteAction
  | TSaveConcreteNoteAction
  | TGetConcreteNotesAction
  | TDeleteMyConcreteNoteAction
  | TGetMyDailyNotesAction
  | TSwitchMyNotesDailyViewModeAction
  | TSaveDailyNoteAction
  | TDeleteMyDailyNoteAction
  | TGetRelativeChatMessagesAction
  | TUpdateChatThreadRecipientUserIdAction
  | TSendMyRelaiveChatMessageAction
  | TCheckForNewChatMessagesAction
  | TGetTreatersAction
  | TGetUserByIdAction
  | TAssignAccessToClientForumAction
  | TRejectClientForumAccessAction
  | TAddUserTreaterRelationAction
  | TValidateUseraliasAction
  | TGetTreaterDeparmentsAction
  | TGetUserMoodRatingsAction
  | TGetNoteAction
  | TSetSelectedNoteTypeAction
  | TUpdateNoteTextAction
  | TGetGamblingFreeCalculatorRelatedUserAction
  | TGetUserMoodRatingsByUserIdAction
  | TGetUserRolesAction
  | TResetUserStateToInitialAction
  | TUpdateUserProfileSettingsAction
  | TGetNewMsgForAnyRelativeAction
  | TNewMessagesCountForRelativeAction
  | TDeleteUserAction
  | TGetAllClientsAction
  ;

