import {
  AcceptInvitationExistingUser,
  AcceptInvitationNewUser,
  AddMyRelative,
  AddUserTreaterRelation,
  AssignAccessToClientForum,
  CheckForNewChatMessages,
  CopyNoteText,
  DeleteMotivatingImage,
  DeleteMyConcreteNote,
  DeleteMyDailyNote,
  DeleteMyNote,
  DeleteMyRelative,
  DeleteMyRelativeInvitation,
  DeleteUser,
  GetConcreteNotes,
  GetCurrentUser,
  GetFamilyInvitationEmail,
  GetGamblingFreeCalculatorRelatedUser,
  GetMotivatingImages,
  GetAllClients,
  GetMyDailyNotes,
  GetMyNotes,
  GetMyRelativeInvitations,
  GetMyRelatives,
  GetNewMsgForAnyRelative,
  GetNote,
  GetRelativeChatMessages,
  GetTreaterDeparments,
  GetTreaters,
  GetUserById,
  GetUserRoles,
  HasPendingInvitations,
  NewMessagesCountForRelative,
  RejectClientForumAccess,
  ResetUserStateToInitial,
  SaveConcreteNote,
  SaveDailyNote,
  SaveFreeNote,
  SaveMyNote,
  SendMyRelaiveChatMessage,
  SetSelectedNoteType,
  SwitchMyNotesDailyViewMode,
  TAcceptInvitationExistingUserAction,
  TAcceptInvitationNewUserAction,
  TAddUserTreaterRelationAction,
  TApiUserEntity,
  TAssignAccessToClientForumAction,
  TCheckForNewChatMessagesAction,
  TCopyNoteTextAction,
  TDeleteMotivatingImageAction,
  TDeleteMyConcreteNoteAction,
  TDeleteMyDailyNoteAction,
  TDeleteMyNoteAction,
  TDeleteMyRelativeAction,
  TDeleteMyRelativeInvitationAction,
  TDeleteUserAction,
  TGetConcreteNotesAction,
  TGetCurrentUserAction,
  TGetFamilyInvitationEmailAction,
  TGetGamblingFreeCalculatorRelatedUserAction,
  TGetMotivatingImagesAction,
  TGetAllClientsAction,
  TGetMyDailyNotesAction,
  TGetMyNotesAction,
  TGetMyRelativeInvitationsAction,
  TGetMyRelativesAction,
  TGetNewMsgForAnyRelativeAction,
  TGetNoteAction,
  TGetRelativeChatMessagesAction,
  TGetTreaterDeparmentsAction,
  TGetTreatersAction,
  TGetUserByIdAction,
  TGetUserRolesAction,
  THasPendingInvitationsAction,
  TNewMessagesCountForRelativeAction,
  TRejectClientForumAccessAction,
  TResetUserStateToInitialAction,
  TSaveConcreteNoteAction,
  TSaveDailyNoteAction,
  TSaveFreeNoteAction,
  TSaveMyNoteAction,
  TSetSelectedNoteTypeAction,
  TSwitchMyNotesDailyViewModeAction,
  TUpdateChatThreadRecipientUserIdAction,
  TUpdateNoteTextAction,
  TUpdateUserProfileAction,
  TUpdateUserProfileSettingsAction,
  TUploadMotivationalImageAction,
  TValidateUseraliasAction,
  UpdateChatThreadRecipientUserId,
  UpdateNoteText,
  UpdateUserProfile,
  UpdateUserProfileSettings,
  UploadMotivationalImage,
  ValidateUseralias,
} from "../../types/User";
import { apiCall, silentApiCall } from "./BaseAction";
import { GetUserMoodRatings, GetUserMoodRatingsByUserId, SetLastMoodDialogShown, SetMood, TGetUserMoodRatingsAction, TGetUserMoodRatingsByUserIdAction, TSetLastMoodDialogShownAction, TSetMoodAction } from "../../types/Mood";
import { Dispatch } from "redux";


// Users
export const getCurrentUser = () => {
  return apiCall<TGetCurrentUserAction, null, TApiUserEntity>(
    GetCurrentUser,
    "GET",
    "/auth/me",
    true
  );
};
export interface IUpdateUserProfileRequest {
  crisis_person_name: string;
  crisis_person_phone: string;
  last_gambled: string | null;
  money_spend_per_period: number | null;
  money_spend_period: string;
  time_spent_per_period: number | null;
  alias: string | null;
}
export const updateUserProfile = (data: IUpdateUserProfileRequest) => {
  return apiCall<TUpdateUserProfileAction, IUpdateUserProfileRequest>(
    UpdateUserProfile,
    "POST",
    "/update-user",
    true,
    { ...data }
  );
};

export interface IUpdateUserProfileSettingsRequest {
  crisis_person_name: string;
  crisis_person_phone: string;
  alias: string | null;
};

export const updateUserProfileSettings = (data: IUpdateUserProfileSettingsRequest) => {
  return apiCall<TUpdateUserProfileSettingsAction, IUpdateUserProfileSettingsRequest>(
    UpdateUserProfileSettings,
    "POST",
    "/update-user-settings",
    true,
    { ...data }
  );
};

export const getUserById = (userId: number) => {
  return apiCall<TGetUserByIdAction>(GetUserById, "GET", "/user/" + userId.toString(), true);
};
export const assignAccesToClientForum = (userId: number) => {
  return apiCall<TAssignAccessToClientForumAction>(
    AssignAccessToClientForum,
    "PUT",
    "/user/assignClientForumAccess/" + userId,
    true
  );
}
export const rejectClientForumAccess = (userId: number) => {
  return apiCall<TRejectClientForumAccessAction>(
    RejectClientForumAccess,
    "PUT",
    "/user/rejectClientForumAccess/" + userId,
    true
  );
};
export type TAddUserTreaterRelationRequest = {
  treater_id: number;
  user_email: string;
};
export const addUserTreaterRelation = (request: TAddUserTreaterRelationRequest) => {
  return apiCall<TAddUserTreaterRelationAction>(
    AddUserTreaterRelation,
    "POST",
    "/user/treaterRelation",
    false,
    request
  );
};
export const validateUserlias = (alias: string) => {
  return apiCall<TValidateUseraliasAction>(
    ValidateUseralias,
    "POST",
    "/user/validate/alias",
    true,
    { alias: alias }
  );
};
export const getUserRoles = () => {
  return apiCall<TGetUserRolesAction>(
    GetUserRoles,
    "GET",
    "/user-roles",
    true
  );
};
export const deleteUser = () => {
  return apiCall<TDeleteUserAction>(
    DeleteUser,
    "DELETE",
    "/user-delete",
    true
  );
};
// \Users


// Motivating Images
export const getMotivatingImages = () => {
  return apiCall<TGetMotivatingImagesAction>(
    GetMotivatingImages,
    "GET",
    "/motivating-images",
    true
  );
};
export const deleteMotivatingImage = (image_id: number) => {
  return apiCall<TDeleteMotivatingImageAction>(
    DeleteMotivatingImage,
    "DELETE",
    "/delete-motivating-image",
    true,
    { image_id }
  );
};
export const uploadMotivationalImage = (image: string) => {
  return apiCall<TUploadMotivationalImageAction>(
    UploadMotivationalImage,
    "POST",
    "/add-motivating-image",
    true,
    { image }
  );
};
// \Motivating Images


// User Mood Ratings
export type TSetUserMoodRequest = {
  date: string | undefined;
  gambling_urge: number;
  message: string;
  rate: number;
}
export const setUserMood = (request: TSetUserMoodRequest) => {
  return async (dispatch : any) => {
    await dispatch(apiCall<TSetMoodAction>(SetMood, "POST", "/add-mood-rating", true, request));
    dispatch(getMyNotes());
  }
};
export const setLastMoodDialogShown = () => {
  return apiCall<TSetLastMoodDialogShownAction>(SetLastMoodDialogShown, "PATCH", "/last-mood-dialog-shown", true);
};
export const getUserMoodRatings = () => {
  return apiCall<TGetUserMoodRatingsAction>(GetUserMoodRatings, "GET", "/moodratings", true);
};
export const getUserMoodRatingsByUserId = (user_id: number) => {
  return apiCall<TGetUserMoodRatingsByUserIdAction>(
    GetUserMoodRatingsByUserId,
    "GET",
    "/moodratings/" + user_id,
    true
  );
};
// \User Mood Ratings


// My Notes
export type TSaveMyNoteRequest = {
  text: string;
  parent_url: string;
};
export const saveMyNote = (data: TSaveMyNoteRequest) => {
  return apiCall<TSaveMyNoteAction>(SaveMyNote, "POST", "/add-note", true, {
    ...data,
  });
};
export type TSaveDailyNoteRequest = {
  text: string;
  created_at: string;
};
export const saveDailyNote = (data: TSaveDailyNoteRequest) => {
  return apiCall<TSaveDailyNoteAction>(SaveDailyNote, "POST", "/add-daily-note", true, {
    ...data,
  });
};
export type TSaveFreeNoteRequest = {
  text: string;
};
export const saveFreeNote = (data: TSaveFreeNoteRequest) => {
  return apiCall<TSaveFreeNoteAction>(SaveFreeNote, "POST", "/add-free-note", true, {
    ...data,
  });
};
export enum ConcreteNoteType {
  MyLinks = "Link",
  MyImages = "Image",
  MyGoals = "Goal",
  MyFunActivities = "FunActivity",
  MyHomeAssignments = "HomeAssignment",
  MyTriggers = "Trigger"
};
export type TSaveConcreteNoteRequest = {
  text: string;
  type: ConcreteNoteType
};
export const saveConcreteNote = (data: TSaveConcreteNoteRequest) => {
  return apiCall<TSaveConcreteNoteAction>(SaveConcreteNote, "POST", "/add-concrete-note", true, {
    ...data,
  });
};
export const getMyNotes = () => {
  return apiCall<TGetMyNotesAction>(GetMyNotes, "GET", "/user-notes", true);
};
export const getConcreteNotes = () => {
  return apiCall<TGetConcreteNotesAction>(GetConcreteNotes, "GET", "/user-notes-concrete", true);
};
export const getMyDailyNotes = () => {
  return apiCall<TGetMyDailyNotesAction>(GetMyDailyNotes, "GET", "/user-notes-daily", true);
};
export const deleteMyNote = (note_id: number) => {
  return apiCall<TDeleteMyNoteAction>(
    DeleteMyNote,
    "DELETE",
    "/delete-note",
    true,
    { note_id }
  );
};
export const deleteConcreteNote = (note_id: number) => {
  return apiCall<TDeleteMyConcreteNoteAction>(
    DeleteMyConcreteNote,
    "DELETE",
    "/delete-concrete-note",
    true,
    { note_id },
  );
};
export const deleteDailyNote = (note_id: number) => {
  return apiCall<TDeleteMyDailyNoteAction>(
    DeleteMyDailyNote,
    "DELETE",
    "/delete-daily-note",
    true,
    { note_id }
  );
};
export const deleteFreeNote = (note_id: number) => {
  return apiCall<TDeleteMyNoteAction>(
    DeleteMyNote,
    "DELETE",
    "/delete-free-note",
    true,
    { note_id },
  );
};
export const copyNoteText = (payload: string) => {
  return (dispatch: Dispatch<TCopyNoteTextAction>) => {
    dispatch({
      type: CopyNoteText.SET,
      payload,
    });
  };
};
export const switchViewModeDailyNotes = (payload: number) => {
  return (dispatch: Dispatch<TSwitchMyNotesDailyViewModeAction>) => {
    dispatch({
      type: SwitchMyNotesDailyViewMode.SET,
      payload: payload,
    });
  };
};
export enum TNoteType {
  DailyNote = "daily-note",
  FreeNote = "free-note",
  Savednote = "saved-note",
};
export type TGetNoteRequest = {
  note_id: string;
  note_type: string;
};
export const getNote = (data: TGetNoteRequest) => {
  return apiCall<TGetNoteAction>(
    GetNote,
    "POST",
    "/note",
    true,
    data
  );
};
export const setSelectedNoteType = (type: TNoteType) => {
  return (dispatch: Dispatch<TSetSelectedNoteTypeAction>) => {
    dispatch({
      type: SetSelectedNoteType.SET,
      payload: type,
    });
  };
};
export type TUpdateNoteTextRequest = {
  note_id: string;
  note_type: string;
  note_text: string;
}
export const updateNoteText = (data: TUpdateNoteTextRequest) => {
  return apiCall<TUpdateNoteTextAction>(
    UpdateNoteText,
    "PUT",
    "/note",
    true,
    data
  )
}
// \My Notes


// My Relatives
export type TAddMyRelativeRequest = {
  email: string
};
export const addMyRelative = (data: TAddMyRelativeRequest) => {
  return apiCall<TAddMyRelativeRequest>(
    AddMyRelative,
    "POST",
    "/family",
    true,
    { ...data }
  );
};
export const deleteMyRelative = (relative_id: number) => {
  return apiCall<TDeleteMyRelativeAction>(
    DeleteMyRelative,
    "DELETE",
    "/family/" + relative_id,
    true
  );
};
export type TDeleteMyRelativeInvitationRequest = {
  email: string
};
export const deleteMyRelativeInvitation = (data: TDeleteMyRelativeInvitationRequest) => {
  return apiCall<TDeleteMyRelativeInvitationAction>(
    DeleteMyRelativeInvitation,
    "DELETE",
    "/family/pendingInvitation/delete",
    true,
    data
  );
};
export const getMyRelatives = () => {
  return apiCall<TGetMyRelativesAction>(GetMyRelatives, "GET", "/family", true);
};
export const getMyRelativeInvitations = () => {
  return apiCall<TGetMyRelativeInvitationsAction>(GetMyRelativeInvitations, "GET", "/familyInvitations", true);
};
export const acceptInvitation_ExistingUser = (user_id: number) => {
  return apiCall<TAcceptInvitationExistingUserAction>(
    AcceptInvitationExistingUser,
    "PUT",
    "/family/accept/" + user_id,
    true
  );
};
export type TAcceptinvitationNewUserRequest = {
  name: string;
  surname: string;
  password: string;
};
export const acceptInvitation_NewUser = (data: TAcceptinvitationNewUserRequest, invitationKey: string) => {
  return apiCall<TAcceptInvitationNewUserAction>(
    AcceptInvitationNewUser,
    "PUT",
    "/family/accept/new/" + invitationKey,
    false,
    data
  );
};
export const hasPendingInvitations = () => {
  return apiCall<THasPendingInvitationsAction>(HasPendingInvitations, "GET", "/family/pendingInvitations", true);
};
export const getFamilyInvitationEmail = (invitationKey: string) => {
  return apiCall<TGetFamilyInvitationEmailAction>(
    GetFamilyInvitationEmail,
    "GET",
    "/family/pendingInvitations/email/" + invitationKey,
    false
  );
};
// \My Relatives


// Chat
export const getRelativeChatMessages = (recipient_id: number) => {
  return apiCall<TGetRelativeChatMessagesAction>(GetRelativeChatMessages, "GET", "/chat/" + recipient_id, true);
};
export const updateChatThreadRecipientUserId = (payload: number) => {
  return (dispatch: Dispatch<TUpdateChatThreadRecipientUserIdAction>) => {
    dispatch({
      type: UpdateChatThreadRecipientUserId.SET,
      payload
    });
  };
};
export type TSendMyRelativeChatMessageRequest = {
  family_member_id: number,
  message: string,
};
export const sendMyRelativeChatMessage = (data: TSendMyRelativeChatMessageRequest) => {
  return apiCall<TSendMyRelativeChatMessageRequest>(
    SendMyRelaiveChatMessage,
    "POST",
    "/chat",
    true,
    data,
  );
};
export type TCheckForNewChatMessagesRequest = {
  recipient_id: number,
  lastReadMessageId: number,
};
export const checkForNewChatMessages = (data: TCheckForNewChatMessagesRequest) => {
  return silentApiCall<TCheckForNewChatMessagesAction>(CheckForNewChatMessages, "GET", "/chat/messages/hasNew", true, null, data);
};
export const getNewMsgCntForAnyRelative = () => {
  return silentApiCall<TGetNewMsgForAnyRelativeAction>(GetNewMsgForAnyRelative, "GET", "/chat/messages/newMsgsFromAnyRelativeCnt", true, null, null);
};
export const getNewMessagesCountForRelative = (relativeId: number) => {
  return apiCall<TNewMessagesCountForRelativeAction>(NewMessagesCountForRelative, "GET", '/chat/messages/getNewMessagesCountForRelative?recipient_id=' + relativeId, true, null, null);
};
// \Chat


// Treaters
export const getTreaters = () => {
  return apiCall<TGetTreatersAction>(GetTreaters, "GET", "/treaters", false);
};
export const getTreaterDepartments = () => {
  return apiCall<TGetTreaterDeparmentsAction>(GetTreaterDeparments, "GET", "/treater-departments", false);
};
// \Treaters


// Gambling Free Calculator
export const getGamblingFreeCalculatorRelatedUser = ()  => {
  return apiCall<TGetGamblingFreeCalculatorRelatedUserAction>(
    GetGamblingFreeCalculatorRelatedUser,
    "GET",
    "/gambling-free-calc/related-user",
    true
  );
};
// \ Gambling Free Calculator


// Logout
export const resetUserStateToInitial = () => {
  return (dispatch: Dispatch<TResetUserStateToInitialAction>) => {
    dispatch({
      type: ResetUserStateToInitial.SET,
    });
  };
};
// \Logout

// Client admin
export const getAllClients = (onlyMyClients: boolean) => {
  return apiCall<TGetAllClientsAction>(
    GetAllClients,
    "GET",
    onlyMyClients ? "/admin/my-clients" : "/admin/all-clients",
    true
  );
};
