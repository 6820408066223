import * as React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import iconClose from "../../assets/images/icon_close.svg";
import { useDialogStyles } from "./Dialog.styles";
import { useTranslation } from "react-i18next";
import mood_1 from "../../assets/images/mood_1.svg";
import mood_2 from "../../assets/images/mood_2.svg";
import mood_3 from "../../assets/images/mood_3.svg";
import mood_4 from "../../assets/images/mood_4.svg";
import mood_5 from "../../assets/images/mood_5.svg";
import { getCurrentUser, getMyDailyNotes, setLastMoodDialogShown, setUserMood } from "../../store/actions/UserActions";
import { useStoreState } from "../../hooks/useStoreState";
import { RouteComponentProps, withRouter } from "react-router";
import { reverse } from "named-urls";
import { routes } from "../../Routes";
import { checkMomentIs1DayAgo } from "../../helpers/Misc";
import moment from "moment";
import { useAppDispatch } from "../../store/Store";
import { UserSettings } from "../../helpers/UserSettings";
import { getUserSettings } from "../../store/actions/UserSettingsActions";

const MoodRatingDialog: React.FC<RouteComponentProps<any>> = ({
  history,
}): JSX.Element => {
  const classes = useDialogStyles();
  const dispatch = useAppDispatch();
  const moods = [mood_1, mood_2, mood_3, mood_4, mood_5];
  const gamblingUrges = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { userState, userSettingsState } = useStoreState();
  const [moodMessage, setMoodMessage] = useState("");
  const [moodValue, setMoodValue] = useState(-1);
  const [open, setOpen] = useState(false);
  const [gamblingUrgeValue, setGamblingUrgeValue] = useState(0);
  const [showGamblingUrgeSelector, setShowGamblingUrgeSelector] = useState<boolean>(false);
  const [showMoodSelector, setShowMoodSelector] = useState<boolean>(false);
  const [showNoteInput, setShowNoteInput] = useState<boolean>(false);
  const { t } = useTranslation();

  const dispatchLoadUserSettings = useCallback(async () => {
    await dispatch(getUserSettings());
  }, [dispatch]);
  useEffect(() => {
    if (!userSettingsState.userSettings && !userSettingsState.loading) {
      dispatchLoadUserSettings();
    }
  }, [userSettingsState.userSettings, userSettingsState.loading, dispatchLoadUserSettings]);

  useEffect(() => {
    if (userState.badMoodReference) {
      history.push(
        reverse(routes.cmsPage, {
          reference: userState.badMoodReference,
        })
      );
    }
  }, [userState.badMoodReference, history]);

  useEffect(() => {
    if (!userState.data) {
      return;
    }
    const showDialog = checkMomentIs1DayAgo(moment(userState.data?.last_mood_dialog_shown));
    if (!showDialog) {
      return;
    }

    const userSettings = userSettingsState.userSettings;
    const showDiag = UserSettings.getShowMoodRatingDialog_Value(userSettings);
    const showDiagNoteInput = UserSettings.getShowMoodRatingDialogDailyNote_Value(userSettings);
    const showDiagMoodSelect = UserSettings.getShowMoodRatingDialogMoodRating_Value(userSettings);
    const showDiagUrgeSelect = UserSettings.getShowMoodRatingDialogGamblingUrge_Value(userSettings);
    if (!showDiag) {
      return;
    }
    if (showDiag && (!showDiagNoteInput && !showDiagMoodSelect && !showDiagUrgeSelect)) {
      return;
    }
    setShowGamblingUrgeSelector(showDiagUrgeSelect ?? true);
    setShowNoteInput(showDiagNoteInput ?? true);
    setShowMoodSelector(showDiagMoodSelect ?? true);
    setOpen(true);
  }, [userState.data, userSettingsState.userSettings]);

  const dispatchSetLastMoodDialogShown = async () => {
    await dispatch(setLastMoodDialogShown());
    await dispatch(getCurrentUser());
  };
  const dispatchSetMoodAction = async (rate: number, message: string, gamblingUrge: number) => {
    await dispatchSetLastMoodDialogShown();
    await dispatch(setUserMood({
      date: undefined,
      gambling_urge: gamblingUrge > 0 ? gamblingUrge : -1,
      message: message,
      rate: rate,
    }));
  };

  const setMood = () => {
    setOpen(false);
    dispatchSetMoodAction(moodValue, moodMessage, gamblingUrgeValue).then(() => {
      dispatch(getMyDailyNotes());
    });
  };

  const handleMoodMessageInputChange = (e: any) => {
    setMoodMessage(e?.target.value ?? "");
  };

  const handleSetMoodValue = (val: number) => {
    setMoodValue(val + 1);
  };

  const handleOnClose = () => {
    setOpen(false);
    dispatchSetLastMoodDialogShown();
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="mood-dialog-title"
      aria-describedby="mood-dialog-description"
    >
      <DialogTitle id="mood-dialog-title">
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleOnClose}
        >
          <img src={iconClose} alt="" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.moodContent} id="mood-dialog-description">
          {showNoteInput && (
            <>
              <div className={classes.moodTitle}>{t("mood.dialog.title")}</div>
              <textarea
                className={classes.moodMessage}
                onChange={handleMoodMessageInputChange}
              ></textarea>
            </>
          )}

          {showMoodSelector && (
            <>
              <div>{t("mood.dialog.header.my.mood")}</div>
              <div className={classes.moodList}>
                {moods.map((mood, index) => {
                  return (
                    <Button
                      id={"moodSelectBtn_" + index.toString()}
                      style={{ flex: 1, minWidth: "unset" }}
                      className={(index + 1 === moodValue && classes.moodBtnSelected) || undefined}
                      key={index}
                      onClick={() => handleSetMoodValue(index)}
                    >
                      <img src={mood} alt="" />
                    </Button>
                  );
                })}
              </div>
            </>
          )}

          {showGamblingUrgeSelector && (
            <>
              <div>{t("mood.dialog.header.my.urges")}</div>
              <input
                type="range"
                style={{ width: '100%', marginTop: '1rem' }}
                className={!gamblingUrgeValue ? 'slider slider-inactive' : 'slider'}
                min="0"
                max="10"
                onChange={({ target }) => setGamblingUrgeValue(parseInt(target.value, 10))}
                value={gamblingUrgeValue}
              />
              <div className={classes.gamblingUrgesList}>
                {gamblingUrges.map((urge, index) => {
                  const active = (index === gamblingUrgeValue);
                  return (
                    <span
                      key={index}
                      style={{
                        color: active ? '#FF5241' : '#000000',
                        fontWeight: active ? 'bold' : 'normal',
                        width: "12px"
                      }}>
                      {urge}
                    </span>
                  )
                })}
              </div>
            </>
          )}

          <Button
            color="primary"
            style={{ marginTop: "10px" }}
            variant="contained"
            onClick={() => setMood()}
          >
            {t("mood.dialog.btn.save")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(MoodRatingDialog);
