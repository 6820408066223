import { TApiPaginatorEntity } from "./User";
import { TForumPostApiEntity, TForumPostCommentApiEntity } from "./Forum";


export interface TRelativeForumState {
  readonly forumPosts_Relatives?: TApiPaginatorEntity<TForumPostApiEntity>;
  readonly hasNewForumMessages_Relatives?: boolean,
  readonly loading?: boolean;
};

export const initialUserState: TRelativeForumState = {
  forumPosts_Relatives: undefined,
  hasNewForumMessages_Relatives: undefined,
  loading: undefined,
};

// Forum
export enum GetForumPostsRelatives {
  REQUEST = "GET_FORUM_POSTS_RELATIVES_REQUEST",
  SUCCESS = "GET_FORUM_POSTS_RELATIVES_SUCCESS",
  FAILED = "GET_FORUM_POSTS_RELATIVES_FAILED",
}
export type TGetForumPostsRelativesAction =
  | {
    type: GetForumPostsRelatives.REQUEST;
  }
  | {
    type: GetForumPostsRelatives.SUCCESS;
    payload: TApiPaginatorEntity<TForumPostApiEntity>;
  }
  | {
    type: GetForumPostsRelatives.FAILED;
    payload: any;
  }

export enum GetForumPostsRelativesPage {
  REQUEST = "GET_FORUM_POSTS_RELATIVES_PAGE_REQUEST",
  SUCCESS = "GET_FORUM_POSTS_RELATIVES_PAGE_SUCCESS",
  FAILED = "GET_FORUM_POSTS_RELATIVES_PAGE_FAILED",
}
export type TGetForumPostsRelativesPageAction =
  | {
    type: GetForumPostsRelativesPage.REQUEST;
  }
  | {
    type: GetForumPostsRelativesPage.SUCCESS;
    payload: TApiPaginatorEntity<TForumPostApiEntity>;
  }
  | {
    type: GetForumPostsRelativesPage.FAILED;
    payload: any;
  }

export enum CreateRelativesForumPost {
  REQUEST = "CREATE_RELATIVES_FORUM_POST_REQUEST",
  SUCCESS = "CREATE_RELATIVES_FORUM_POST_SUCCESS",
  FAILED = "CREATE_RELATIVES_FORUM_POST_FAILED",
}
export type TCreateRelativesForumPostAction =
  | {
    type: CreateRelativesForumPost.REQUEST;
  }
  | {
    type: CreateRelativesForumPost.SUCCESS;
    payload: any;
  }
  | {
    type: CreateRelativesForumPost.FAILED;
    payload: any;
  }

export enum CreateRelativesForumPostComment {
  REQUEST = "CREATE_RELATIVES_FORUM_POST_COMMENT_REQUEST",
  SUCCESS = "CREATE_RELATIVES_FORUM_POST_COMMENT_SUCCESS",
  FAILED = "CREATE_RELATIVES_FORUM_POST_COMMENT_FAILED",
}
export type TCreateRelativesForumPostCommentAction =
  | {
    type: CreateRelativesForumPostComment.REQUEST;
  }
  | {
    type: CreateRelativesForumPostComment.SUCCESS;
    payload: TForumPostCommentApiEntity;
  }
  | {
    type: CreateRelativesForumPostComment.FAILED;
    payload: any;
  }

export enum GetForumPostRelatives {
  REQUEST = "GET_FORUM_POST_RELATIVES_REQUEST",
  SUCCESS = "GET_FORUM_POST_RELATIVES_SUCCESS",
  FAILED = "GET_FORUM_POST_RELATIVES_FAILED",
}
export type TGetForumPostRelativesAction =
  | {
    type: GetForumPostRelatives.REQUEST;
  }
  | {
    type: GetForumPostRelatives.SUCCESS;
    payload: TForumPostApiEntity;
  }
  | {
    type: GetForumPostRelatives.FAILED;
    payload: any;
  }

export enum DeleteForumPostRelatives {
  REQUEST = "DELETE_FORUM_POST_RELTAITVES_REQUEST",
  SUCCESS = "DELETE_FORUM_POST_RELTAITVES_SUCCESS",
  FAILED = "DELETE_FORUM_POST_RELTAITVES_FAILED",
}
export type TDeleteForumPostRelativesAction =
  | {
    type: DeleteForumPostRelatives.REQUEST;
  }
  | {
    type: DeleteForumPostRelatives.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteForumPostRelatives.FAILED;
    payload: any;
  }

export enum DeleteForumPostCommentRelatives {
  REQUEST = "DELETE_FORUM_POST_COMMENT_RELATIVES_REQUEST",
  SUCCESS = "DELETE_FORUM_POST_COMMENT_RELATIVES_SUCCESS",
  FAILED = "DELETE_FORUM_POST_COMMENT_RELATIVES_FAILED",
};
export type TDeleteForumPostCommentRelativesAction =
  | {
    type: DeleteForumPostCommentRelatives.REQUEST;
  }
  | {
    type: DeleteForumPostCommentRelatives.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteForumPostCommentRelatives.FAILED;
    payload: any;
  }

export enum HasAnyNewMessagesRelativesForum {
  SILENTREQUEST = "HAS_ANY_NEW_MESSAGES_RELATIVES_FORUM_SILENTREQUEST",
  SILENTSUCCESS = "HAS_ANY_NEW_MESSAGES_RELATIVES_FORUM_SILENTUCCESS",
  SILENTFAILED = "HAS_ANY_NEW_MESSAGES_RELATIVES_FORUM_SILENTFAILED",
};
export type THasAnyNewMessagesRelativesForumAction =
  | {
    type: HasAnyNewMessagesRelativesForum.SILENTREQUEST;
  }
  | {
    type: HasAnyNewMessagesRelativesForum.SILENTSUCCESS;
    payload: any;
  }
  | {
    type: HasAnyNewMessagesRelativesForum.SILENTFAILED;
    payload: any;
  }
// \Forum


export type TRelativeForumActions =
  | TGetForumPostsRelativesAction
  | TGetForumPostsRelativesPageAction
  | TCreateRelativesForumPostAction
  | TCreateRelativesForumPostCommentAction
  | TGetForumPostRelativesAction
  | TDeleteForumPostRelativesAction
  | TDeleteForumPostCommentRelativesAction
  | THasAnyNewMessagesRelativesForumAction
  ;

