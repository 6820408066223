import {
  AcceptInvitationExistingUser,
  AddMyRelative,
  CheckForNewChatMessages,
  CopyNoteText,
  DeleteMyRelative,
  DeleteMyRelativeInvitation,
  GetConcreteNotes,
  GetCurrentUser,
  GetFamilyInvitationEmail,
  GetGamblingFreeCalculatorRelatedUser,
  GetMotivatingImages,
  GetMyDailyNotes,
  GetMyNotes,
  GetMyRelativeInvitations,
  GetMyRelatives,
  GetNote,
  GetRelativeChatMessages,
  GetTreaterDeparments,
  GetTreaters,
  GetUserRoles,
  HasPendingInvitations,
  initialUserState,
  ResetUserStateToInitial,
  SaveConcreteNote,
  SaveDailyNote,
  SaveFreeNote,
  SaveMyNote,
  SendMyRelaiveChatMessage,
  SetMood,
  SetSelectedNoteType,
  SwitchMyNotesDailyViewMode,
  TUserActions,
  TUserState,
  UpdateChatThreadRecipientUserId,
  UpdateNoteText,
  UpdateUserProfile,
  UploadMotivationalImage,
  ValidateUseralias,
  UpdateUserProfileSettings,
  GetNewMsgForAnyRelative,
  NewMessagesCountForRelative,
  DeleteUser,
  GetAllClients,
} from "../../types/User";
import { Reducer } from "redux";
import { sortBy } from "lodash";
import { GetUserMoodRatings, GetUserMoodRatingsByUserId } from "../../types/Mood";

export const usersReducer: Reducer<TUserState, TUserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case GetCurrentUser.REQUEST:
    case UpdateUserProfile.REQUEST:
    case SetMood.REQUEST:
    case GetMotivatingImages.REQUEST:
    case GetAllClients.REQUEST:
    case SaveMyNote.REQUEST:
    case GetMyNotes.REQUEST:
    case GetMyRelatives.REQUEST:
    case AddMyRelative.REQUEST:
    case DeleteMyRelative.REQUEST:
    case HasPendingInvitations.REQUEST:
    case GetMyRelativeInvitations.REQUEST:
    case DeleteMyRelativeInvitation.REQUEST:
    case GetFamilyInvitationEmail.REQUEST:
    case AcceptInvitationExistingUser.REQUEST:
    case SaveFreeNote.REQUEST:
    case SaveConcreteNote.REQUEST:
    case GetConcreteNotes.REQUEST:
    case GetMyDailyNotes.REQUEST:
    case SaveDailyNote.REQUEST:
    case UploadMotivationalImage.REQUEST:
    case GetRelativeChatMessages.REQUEST:
    case SendMyRelaiveChatMessage.REQUEST:
    case GetTreaters.REQUEST:
    case ValidateUseralias.REQUEST:
    case GetTreaterDeparments.REQUEST:
    case GetUserMoodRatings.REQUEST:
    case GetNote.REQUEST:
    case UpdateNoteText.REQUEST:
    case GetGamblingFreeCalculatorRelatedUser.REQUEST:
    case GetUserMoodRatingsByUserId.REQUEST:
    case GetUserRoles.REQUEST:
    case UpdateUserProfileSettings.REQUEST:
    case NewMessagesCountForRelative.REQUEST:
    case DeleteUser.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetNewMsgForAnyRelative.SILENTREQUEST:
    case GetNewMsgForAnyRelative.SILENTFAILED:
    case CheckForNewChatMessages.SILENTREQUEST:
    case CheckForNewChatMessages.SILENTFAIELD:
      return {
        ...state,
      };

    case GetMyNotes.FAILED:
    case GetMotivatingImages.FAILED:
    case GetAllClients.FAILED:
    case SaveMyNote.FAILED:
    case SetMood.FAILED:
    case UpdateUserProfile.FAILED:
    case GetCurrentUser.FAILED:
    case GetMyRelatives.FAILED:
    case AddMyRelative.FAILED:
    case DeleteMyRelative.FAILED:
    case HasPendingInvitations.FAILED:
    case GetMyRelativeInvitations.FAILED:
    case DeleteMyRelativeInvitation.FAILED:
    case GetFamilyInvitationEmail.FAILED:
    case AcceptInvitationExistingUser.FAILED:
    case SaveFreeNote.FAILED:
    case SaveConcreteNote.FAILED:
    case GetConcreteNotes.FAILED:
    case GetMyDailyNotes.FAILED:
    case SaveDailyNote.FAILED:
    case UploadMotivationalImage.FAILED:
    case GetRelativeChatMessages.FAILED:
    case SendMyRelaiveChatMessage.FAILED:
    case GetTreaters.FAILED:
    case ValidateUseralias.FAILED:
    case GetTreaterDeparments.FAILED:
    case GetUserMoodRatings.FAILED:
    case GetNote.FAILED:
    case UpdateNoteText.FAILED:
    case GetGamblingFreeCalculatorRelatedUser.FAILED:
    case GetUserMoodRatingsByUserId.FAILED:
    case GetUserRoles.FAILED:
    case UpdateUserProfileSettings.FAILED:
    case NewMessagesCountForRelative.FAILED:
    case DeleteUser.FAILED:
      return {
        ...state,
        loading: false,
      };

    // Users
    case GetCurrentUser.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.user,
      };
    case UpdateUserProfile.SUCCESS:
    case UpdateUserProfileSettings.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ValidateUseralias.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GetUserRoles.SUCCESS:
      return {
        ...state,
        loading: false,
        userRoles: action.payload
      }
    case DeleteUser.SUCCESS:
      return {
        ...initialUserState,
        loading: false,
      }
    // \Users



    // Mood
    case SetMood.SUCCESS:
      return {
        ...state,
        loading: false,
        badMoodReference: action.payload["redirect-reference"],
      };
    case GetUserMoodRatings.SUCCESS:
      return {
        ...state,
        loading: false,
        moodRatings: action.payload,
      };
    case GetUserMoodRatingsByUserId.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    // \Mood



    // Motivating Images
    case GetMotivatingImages.SUCCESS:
      return {
        ...state,
        loading: false,
        motivatingImages: sortBy(action.payload, (i) => i.user_id),
      };
    case UploadMotivationalImage.SUCCESS:
      return {
        ...state,
        loading: false
      };
    // \Motivating Images

    // My clients
    case GetAllClients.SUCCESS:
      return {
        ...state,
        loading: false,
        myClients: action.payload,
      };
    // \My clients


    //My Notes
    case SaveMyNote.SUCCESS:
      return {
        ...state,
        loading: false,
        tempNoteText: undefined,
        noteSaved: true,
      };
    case SaveConcreteNote.SUCCESS:
    case SaveDailyNote.SUCCESS:
    case SaveFreeNote.SUCCESS:
    case UpdateNoteText.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CopyNoteText.SET:
      return {
        ...state,
        tempNoteText: action.payload,
        noteSaved: false,
      };
    case GetMyNotes.SUCCESS:
      return {
        ...state,
        loading: false,
        myNotes: sortBy(action.payload, (i) => i.user_id),
      };
    case GetMyDailyNotes.SUCCESS:
      return {
        ...state,
        loading: false,
        myNotesDaily: sortBy(action.payload, (i) => i.created_at),
      };
    case GetConcreteNotes.SUCCESS:
      return {
        ...state,
        loading: false,
        myNotesConcrete: action.payload,
      };
    case SwitchMyNotesDailyViewMode.SET:
      return {
        ...state,
        myNotesDaily_ViewMode: action.payload,
      };
    case GetNote.SUCCESS:
      return {
        ...state,
        loading: false,
        myNotesSelectedNote: action.payload,
      };
    case SetSelectedNoteType.SET:
      return {
        ...state,
        myNotesSelectedNoteType: action.payload,
      }
    // \My Notes



    // My Relatives
    case GetMyRelatives.SUCCESS:
      return {
        ...state,
        loading: false,
        myRelatives: sortBy(action.payload, [(i: any) => i.accepted, (i: any) => i.family_member.name]),
      };
    case AddMyRelative.SUCCESS:
    case DeleteMyRelative.SUCCESS:
    case DeleteMyRelativeInvitation.SUCCESS:
    case GetFamilyInvitationEmail.SUCCESS:
    case AcceptInvitationExistingUser.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case HasPendingInvitations.SUCCESS:
      return {
        ...state,
        loading: false,
        hasPendingInvitations: action.payload,
      };
    case GetMyRelativeInvitations.SUCCESS:
      return {
        ...state,
        loading: false,
        myRelativeInvitations: action.payload,
      };
    // \My Relatives



    // Chat
    case GetRelativeChatMessages.SUCCESS:
      return {
        ...state,
        loading: false,
        chatThreadMessages: action.payload,
      };
    case UpdateChatThreadRecipientUserId.SET:
      return {
        ...state,
        chatThreadRecipientUserId: action.payload,
      };
    case SendMyRelaiveChatMessage.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CheckForNewChatMessages.SILENTSUCCESS:
      return {
        ...state,
      };

    case GetNewMsgForAnyRelative.SILENTSUCCESS:
      return {
        ...state,
        chatMessageCountAllRelatives: action.payload,
      };

    case NewMessagesCountForRelative.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    // \Chat


    //Treaters
    case GetTreaters.SUCCESS:
      return {
        ...state,
        loading: false,
        treaterList: action.payload,
      };
    case GetTreaterDeparments.SUCCESS:
      return {
        ...state,
        loading: false,
        treaterDepartments: action.payload,
      };
    // \Treaters


    // Gambling Free Calculaotr
    case GetGamblingFreeCalculatorRelatedUser.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    // \Gambling Free Calculator


    //Logout
    case ResetUserStateToInitial.SET:
      return initialUserState;
    // \Logout


    default:
      return state;
  }
};
