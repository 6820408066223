import * as React from "react";
import { TApiMenuEntity } from "../../types/Menu";
import arrow_right from "../../assets/images/icon_arrow_right.svg";
import HolderItem from "./item-types/HolderItem";
import BasicMenuLink from "./item-types/BasicMenuLink";
import { useStoreState } from "../../hooks/useStoreState";

interface INavbarItemProps {
  menuItem: TApiMenuEntity;
  closeMenu?: () => void;
}

const NavbarItem: React.FC<INavbarItemProps> = ({
  menuItem: {
    title,
    route,
    items,
    notificationKey,
    iconKey,
    disabled,
    external,
  },
  closeMenu,
}): JSX.Element => {
  const { relativeForumState, clientForumState, userState } = useStoreState();
  const notifications: { [key: string]: boolean } = {
    'forum-clients': !!clientForumState.hasNewForumMessages_Client,
    'forum-relatives': !!relativeForumState.hasNewForumMessages_Relatives,
    'chat-messages': userState.chatMessageCountAllRelatives ? userState.chatMessageCountAllRelatives > 0 : false,
  };

  if (items) {
    return (
      <HolderItem
        title={title}
        subMenu={items}
        icon={arrow_right}
        closeMenuOnClick={closeMenu}
      />
    );
  }

  return (
    <BasicMenuLink
      closeMenuOnClick={closeMenu}
      title={title}
      to={route}
      showNotification={notifications[notificationKey!]}
      disabled={disabled}
      external={external}
      iconKey={iconKey}
    />
  );
};

export default NavbarItem;
