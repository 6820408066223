import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRoutesStyles = makeStyles(
    (theme: Theme) => 
        createStyles({
            main: {
                paddingBottom: "60px",
            },
        }),
    { index: 1 }
);