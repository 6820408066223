export const setPlayerId = (externalId: string) => {
    if (isIosApp()) {
        //@ts-ignore
        window.webkit?.messageHandlers?.oneSignalObserver_SetId?.postMessage(externalId);
    } else if (isAndroidApp()) {
        //@ts-ignore
        JSBridge?.oneSignalSetPlayerId(externalId);
    } else {
        console.log("setPlayerId: Not in app");
    }
}

export const unsetPlayerId = () => {
    console.log('unsetPlayerId');
    if (isIosApp()) {
        //@ts-ignore
        window.webkit?.messageHandlers?.oneSignalObserver_UnsetId?.postMessage("empty");
    } else if (isAndroidApp()) {
        //@ts-ignore
        JSBridge?.oneSignalUnSetPlayerId("empty");
    } else {
        console.log("unsetPlayerId: Not in app");
    }
}

export const native = {
    handleNotification(data: any) {
        if (data == null) {
            return;
        }
        console.log('handleNotification', JSON.stringify(data));
        //@ts-ignore
        if (window.location.href.endsWith(data.route)) {
            return;
        }
        window.location.href = data.route;
    }
}

function isIosApp() {
    //@ts-ignore
    return typeof webkit !== 'undefined' && window.navigator.userAgent.includes("CFL_InApp");
}

function isAndroidApp() {
    //@ts-ignore
    return typeof JSBridge === 'object' && window.navigator.appVersion.includes("ludomani");
}
