import {
  initialUserState,
  TRelativeForumState,
  TRelativeForumActions,
  CreateRelativesForumPost,
  CreateRelativesForumPostComment,
  DeleteForumPostRelatives,
  DeleteForumPostCommentRelatives,
  GetForumPostRelatives,
  GetForumPostsRelatives,
  GetForumPostsRelativesPage,
  HasAnyNewMessagesRelativesForum,
} from "../../types/RelativeForum";
import { Reducer } from "redux";

export const relativeForumReducer: Reducer<TRelativeForumState, TRelativeForumActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case GetForumPostsRelatives.REQUEST:
    case GetForumPostRelatives.REQUEST:
    case GetForumPostsRelativesPage.REQUEST:
    case CreateRelativesForumPost.REQUEST:
    case CreateRelativesForumPostComment.REQUEST:
    case DeleteForumPostRelatives.REQUEST:
    case DeleteForumPostCommentRelatives.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case HasAnyNewMessagesRelativesForum.SILENTREQUEST:
      return {
        ...state,
      };

    case GetForumPostsRelatives.FAILED:
    case GetForumPostRelatives.FAILED:
    case GetForumPostsRelativesPage.FAILED:
    case CreateRelativesForumPost.FAILED:
    case CreateRelativesForumPostComment.FAILED:
    case DeleteForumPostRelatives.FAILED:
    case DeleteForumPostCommentRelatives.FAILED:
    case HasAnyNewMessagesRelativesForum.SILENTFAILED:
      return {
        ...state,
        loading: false,
      };


    //Forum
    case GetForumPostsRelatives.SUCCESS:
    case GetForumPostsRelativesPage.SUCCESS:
      return {
        ...state,
        loading: false,
        forumPosts_Relatives: action.payload,
      };
    case GetForumPostRelatives.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CreateRelativesForumPost.SUCCESS:
    case CreateRelativesForumPostComment.SUCCESS:
    case DeleteForumPostRelatives.SUCCESS:
    case DeleteForumPostCommentRelatives.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case HasAnyNewMessagesRelativesForum.SILENTSUCCESS:
      return {
        ...state,
        loading: false,
        hasNewForumMessages_Relatives: action.payload.new_msgs_count > 0,
      };
    // \Forum


    default:
      return state;
  }
};
