import {
  initialUserState,
  TUserSettingsState,
  TUserSettingsActions,
  AddOrUpdateUserSetting,
  AddOrUpdateUserSettings,
  GetUserSettings,
  GetUserSettingsByUserId,
} from "../../types/UserSettings";
import { Reducer } from "redux";
import { sortBy } from "lodash";

export const userSettingsReducer: Reducer<TUserSettingsState, TUserSettingsActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case GetUserSettings.REQUEST:
    case AddOrUpdateUserSetting.REQUEST:
    case AddOrUpdateUserSettings.REQUEST:
    case GetUserSettingsByUserId.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetUserSettings.FAILED:
    case AddOrUpdateUserSetting.FAILED:
    case AddOrUpdateUserSettings.FAILED:
    case GetUserSettingsByUserId.FAILED:
      return {
        ...state,
        loading: false,
      };

    case GetUserSettings.SUCCESS:
      return {
        ...state,
        loading: false,
        userSettings: action.payload
      };
    case AddOrUpdateUserSetting.SUCCESS:
    case AddOrUpdateUserSettings.SUCCESS:
    case GetUserSettingsByUserId.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
