import { Button, Tooltip } from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";
import { useNavbarStyles } from "../Navbar.styles";
import bullhorn_black from "../../../assets/images/bullhorn_black.svg";
import arrow_right from "../../../assets/images/icon_arrow_right.svg";
import icon_phone from "../../../assets/images/icon-phone.svg";
import ExitToApp from "@material-ui/icons/ExitToApp";

interface IBasicMenuLinkLinkProps {
  closeMenuOnClick?: () => void;
  title: string;
  to?: string;
  disabled?: string;
  showNotification?: boolean;
  external?: boolean;
  iconKey?: string;
};

const BasicMenuLinkLink: React.FC<IBasicMenuLinkLinkProps> = ({
  closeMenuOnClick,
  title,
  to,
  disabled,
  showNotification,
  external,
  iconKey,
}): JSX.Element => {
  const classes = useNavbarStyles({});
  const icons: { [key: string]: JSX.Element } = {
    'phone': <img src={icon_phone} alt="phone" />,
    'logout': <ExitToApp color="primary" />,
  };
  function button() {
    return (
      <Button
        className={classes.menuItem}
        onClick={closeMenuOnClick}
        component={external ? 'a' : Link}
        to={to || '#'}
        href={external ? to : undefined}
        disabled={!!disabled}
      >
        <div>
          {title}
        </div>
        <div className={classes.menuItemContentImgContainer}>
          {showNotification && (
            <img src={bullhorn_black} alt="" className={classes.menuItemContentNewMsgImg} />
          )}
        </div>
        <div>
          {(iconKey ? icons[iconKey] : null) || <img src={arrow_right} alt="arrow" />}
        </div>
      </Button>
    );
  }
  if (disabled) {
    return (
      <Tooltip
        title={disabled}
        placement="top"
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
        disableTouchListener={!disabled}
      >
        <span style={{ width: "100%" }}>
          {button()}
        </span>
      </Tooltip>
    );
  } else {
    return button();
  }
};

export default BasicMenuLinkLink;
