import {
  initialUserState,
  TClientForumState,
  TClientForumActions,
  CreateClientForumPost,
  CreateClientForumPostComment,
  DeleteForumPostClient,
  DeleteForumPostCommentClient,
  GetForumPostClient,
  GetForumPostsClient,
  GetForumPostsClientPage,
  RequestClientForumAccess,
  HasAnyNewMessagesClientForum,
} from "../../types/ClientForum";
import { Reducer } from "redux";

export const clientForumReducer: Reducer<TClientForumState, TClientForumActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case GetForumPostsClient.REQUEST:
    case GetForumPostClient.REQUEST:
    case GetForumPostsClientPage.REQUEST:
    case RequestClientForumAccess.REQUEST:
    case CreateClientForumPost.REQUEST:
    case CreateClientForumPostComment.REQUEST:
    case DeleteForumPostClient.REQUEST:
    case DeleteForumPostCommentClient.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case HasAnyNewMessagesClientForum.SILENTREQUEST:
      return {
        ...state,
      };

    case GetForumPostsClient.FAILED:
    case GetForumPostClient.FAILED:
    case GetForumPostsClientPage.FAILED:
    case RequestClientForumAccess.FAILED:
    case CreateClientForumPost.FAILED:
    case CreateClientForumPostComment.FAILED:
    case DeleteForumPostClient.FAILED:
    case DeleteForumPostCommentClient.FAILED:
    case HasAnyNewMessagesClientForum.SILENTFAILED:
      return {
        ...state,
        loading: false,
      };


    //Forum
    case GetForumPostsClient.SUCCESS:
    case GetForumPostsClientPage.SUCCESS:
      return {
        ...state,
        loading: false,
        forumPosts_Client: action.payload,
      };
    case GetForumPostClient.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RequestClientForumAccess.SUCCESS:
    case CreateClientForumPost.SUCCESS:
    case CreateClientForumPostComment.SUCCESS:
    case DeleteForumPostClient.SUCCESS:
    case DeleteForumPostCommentClient.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case HasAnyNewMessagesClientForum.SILENTSUCCESS:
      return {
        ...state,
        loading: false,
        hasNewForumMessages_Client: action.payload.new_msgs_count > 0,
      };
    // \Forum


    default:
      return state;
  }
};
