import React, { useCallback, useEffect, Suspense } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useStoreState } from "../../hooks/useStoreState";
import { TDispatch } from "../../types/Thunk";
import { withRouter, RouteComponentProps } from "react-router";
import { routes } from "../../Routes";
import { getAccessToken } from "../../helpers/Misc";
import { getCurrentUser, getNewMsgCntForAnyRelative } from "../../store/actions/UserActions";
import { getUserSettings } from "../../store/actions/UserSettingsActions";
import Navbar from "../../components/navigation/Navbar";
import { getCrisisMenu, getMainMenu } from "../../store/actions/MenuActions";
import MoodRatingDialog from "../../components/dialogs/MoodRatingDialog";
import CrisisMenuButton from "../../components/CrisisMenuButton";
import { useRoutesStyles } from "./Routes.styles";
import { useApp } from "../../hooks/useApp";
import { useAppDispatch } from "../../store/Store";
import { Button } from "@material-ui/core";
import { t } from "i18next";
import { setPlayerId } from "../../helpers/native-bridge";
import { hasAnyNewMessagesClientForum } from "../../store/actions/ClientForumActions";
import { hasAnyNewMessagesRelativesForum } from "../../store/actions/RelativeForumActions";

let reloadMsgsTimer: NodeJS.Timeout | null = null;

interface IPrivateRouteProps extends RouteProps {
  component: React.ComponentClass | React.FC;
}

const PrivateRoute: React.FC<IPrivateRouteProps & RouteComponentProps> = (
  props,
  ...rest
) => {
  const { app } = useApp();
  const classes = useRoutesStyles();
  const dispatch = useAppDispatch();
  const userState = useStoreState().userState;

  function startGetNewMsgs(dispatch: TDispatch) {
    if (!userState.data || !getAccessToken()) { // Don't begin check loop if not logged in
      reloadMsgsTimer = null;
      return;
    }

    if (reloadMsgsTimer) {
      return;
    }

    const checkFunc = (dispatch: TDispatch) => {
      if (!getAccessToken() && reloadMsgsTimer) { // Stop interval if logged out
        clearInterval(reloadMsgsTimer);
        return;
      }

      dispatch(getNewMsgCntForAnyRelative());
      dispatch(hasAnyNewMessagesClientForum());
      dispatch(hasAnyNewMessagesRelativesForum());
    };

    checkFunc(dispatch);
    reloadMsgsTimer = setInterval(() => checkFunc(dispatch), 15000);
  }

  // On navigation - If not logged in, go to login page
  useEffect(() => {
    if (!getAccessToken()) {
      return props.history.push(routes.loginPage);
    }
  }, [props.history]);

  const dispatchGetMenu = useCallback(async () => {
    if (getAccessToken()) {
      await dispatch(getMainMenu());
      return;
    }
  }, [dispatch]);

  // Load main-menu and start continuous check for new messages
  useEffect(() => {
    dispatchGetMenu();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    startGetNewMsgs(dispatch);
    //eslint-disable-next-line
  }, [userState.data, userState.loading]);

  const getUser = useCallback(async () => {
    if (!userState.data && !app.isLoading() && !!getAccessToken()) {
      await dispatch(getCurrentUser());
      await dispatch(getUserSettings());
      await dispatch(getCrisisMenu());
    }
  }, [app, dispatch, userState.data]);

  getUser();

  return (
    <Suspense fallback={""}>
      <Navbar />
      {userState.data && (
        <>
          <MoodRatingDialog />
        </>
      )}

      <main className={classes.main}>
        <Route component={props.component} exact path={props.path} {...rest} />

        {props.history.location.key && (props.path && props.path !== '/') && (
          <Button
            color="primary"
            onClick={() => props.history.goBack()}>
            <span>{t("input.modal.cancel")}</span>
          </Button>
        )}
      </main>

      <CrisisMenuButton />
    </Suspense>
  );
};

export default withRouter(PrivateRoute);
