import * as React from "react";
import { Dialog } from "@material-ui/core";
import { useStoreState } from "../../../hooks/useStoreState";
import {
  setNavbarMenuOpen,
  setSubMenuOpen,
} from "../../../store/actions/MenuActions";
import NavbarItem from "../NavbarItem";
import { useSubMenuStyles } from "./SubMenu.styles";
import { MenuType } from "../../../types/Menu";
import phoneIcon from "../../../assets/images/icon-phone.svg";
import PhoneLink from "../item-types/PhoneLink";
import { useAppDispatch } from "../../../store/Store";

const SubMenuDialog: React.FC = (): JSX.Element => {
  const menuState = useStoreState().menuState;
  const userState = useStoreState().userState;
  const dispatch = useAppDispatch();
  const classes = useSubMenuStyles();

  const closeMenu = () => {
    dispatch(setNavbarMenuOpen(false));
    dispatch(setSubMenuOpen([]));
  };

  return (
    <Dialog
      className={
        menuState.activeMenuType === MenuType.CRISIS
          ? classes.crisisMenuDialog
          : ""
      }
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      onClose={() => dispatch(setSubMenuOpen([]))}
      aria-labelledby="simple-dialog-title"
      open={!!menuState.activeSubMenu && menuState.activeSubMenu.length > 0}
    >
      <>
        {menuState.activeMenuType === MenuType.CRISIS &&
          userState.data?.crisis_person_phone && (
            <PhoneLink
              url={`tel:${userState.data?.crisis_person_phone}`}
              closeMenuOnClick={closeMenu}
              icon={phoneIcon}
              title={`${userState.data?.crisis_person_name}`}
            />
          )}
        {menuState.activeSubMenu?.map((menuItem, index) => {
          return (
            <NavbarItem closeMenu={closeMenu} key={index} menuItem={menuItem} />
          );
        })}
      </>
    </Dialog>
  );
};

export default SubMenuDialog;
