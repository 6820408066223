import axios, { AxiosResponse, Method } from "axios";
import { Dispatch } from "redux";
import { TDispatch } from "../../types/Thunk";
import { getAccessToken, removeAuthInfoFromLocalStorage } from "../../helpers/Misc";
import { SetAppError } from "../../types/Error";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

const handleUnauthorized = (err: any) => {
  if (err?.response?.status === 401) {
    removeAuthInfoFromLocalStorage();
    window.location.reload();
  }
};

export const silentApiCall = <
  TAction = any,
  TRequestData = any,
  TResponse extends {} = {}
>(
  action: any,
  method: Method,
  url: string,
  authorized: boolean = false,
  data: TRequestData | null = {} as TRequestData,
  params: any = {}
) => {
  return async (
    dispatch: TDispatch<TAction>
  ): Promise<AxiosResponse<TResponse>> => {
    dispatch({
      type: action.SILENTREQUEST,
    });
    try {
      if (authorized) {
        apiClient.interceptors.request.use((request) => {
          request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
          return request;
        });
      }

      return await apiClient
        .request<TResponse>({
          data,
          method,
          params,
          url,
        })
        .then((res: AxiosResponse<TResponse>) => {
          dispatch({
            type: action.SILENTSUCCESS,
            payload: res.data,
          });

          return res;
        });
    } catch (err) {
      dispatch({
        type: action.SILENTFAILED,
        payload: err,
      });
      handleUnauthorized(err);
      throw err;
    }
  };
};

export const apiCall = <
  TAction = any,
  TRequestData = any,
  TResponse extends {} = {}
>(
  action: any,
  method: Method,
  url: string,
  authorized: boolean = false,
  data: TRequestData | null = {} as TRequestData,
  params: any = {}
) => {
  return async (
    dispatch: TDispatch<TAction>
  ): Promise<AxiosResponse<TResponse>> => {
    dispatch({
      type: action.REQUEST,
    });
    try {
      if (authorized) {
        apiClient.interceptors.request.use((request) => {
          request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
          return request;
        });
      }

      return await apiClient
        .request<TResponse>({
          data,
          method,
          params,
          url,
        })
        .then((res: AxiosResponse<TResponse>) => {
          dispatch({
            type: action.SUCCESS,
            payload: res.data,
          });

          return res;
        });
    } catch (err: any) {
      dispatch({
        type: action.FAILED,
        payload: err,
      });
      handleUnauthorized(err);
      throw err;
    }
  };
};

interface IErrorPayload {
  message: string;
  code: number;
}

export const errorAction = (payload: IErrorPayload) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SetAppError.SUCCESS,
      payload,
    });
  };
};
