import { TForumPostApiEntity } from "./Forum";
import { TApiPaginatorEntity } from "./User";

export interface TClientForumState {
  readonly forumPosts_Client?: TApiPaginatorEntity<TForumPostApiEntity>;
  readonly hasNewForumMessages_Client?: boolean,
  readonly loading?: boolean;
};

export const initialUserState: TClientForumState = {
  forumPosts_Client: undefined,
  hasNewForumMessages_Client: undefined,
  loading: undefined,
};

export enum AssignAccessToClientForum {
  REQUEST = "ASSIGN_ACCESS_TO_CLIENT_FORUM_REQUEST",
  SUCCESS = "ASSIGN_ACCESS_TO_CLIENT_FORUM_SUCCESS",
  FAILED = "ASSIGN_ACCESS_TO_CLIENT_FORUM_FAILED",
};
export type TAssignAccessToClientForumAction =
  | {
    type: AssignAccessToClientForum.REQUEST,
  }
  | {
    type: AssignAccessToClientForum.SUCCESS,
    payload: any,
  }
  | {
    type: AssignAccessToClientForum.FAILED,
    payload: any,
  }

export enum RejectClientForumAccess {
  REQUEST = "REJECT_CLIENT_FORUM_ACCESS_REQUEST",
  SUCCESS = "REJECT_CLIENT_FORUM_ACCESS_SUCCESS",
  FAILED = "REJECT_CLIENT_FORUM_ACCESS_FAILED",
}
export type TRejectClientForumAccessAction =
  | {
    type: RejectClientForumAccess.REQUEST;
  }
  | {
    type: RejectClientForumAccess.SUCCESS;
    payload: any;
  }
  | {
    type: RejectClientForumAccess.FAILED;
    payload: any;
  }


// Forum
export enum GetForumPostsClient {
  REQUEST = "GET_FORUM_POSTS_CLIENT_REQUEST",
  SUCCESS = "GET_FORUM_POSTS_CLIENT_SUCCESS",
  FAILED = "GET_FORUM_POSTS_CLIENT_FAILED",
}
export type TGetForumPostsClientAction =
  | {
    type: GetForumPostsClient.REQUEST;
  }
  | {
    type: GetForumPostsClient.SUCCESS;
    payload: TApiPaginatorEntity<TForumPostApiEntity>;
  }
  | {
    type: GetForumPostsClient.FAILED;
    payload: any;
  }

export enum GetForumPostClient {
  REQUEST = "GET_FORUM_POST_CLIENT_REQUEST",
  SUCCESS = "GET_FORUM_POST_CLIENT_SUCCESS",
  FAILED = "GET_FORUM_POST_CLIENT_FAILED",
}
export type TGetForumPostClientAction =
  | {
    type: GetForumPostClient.REQUEST;
  }
  | {
    type: GetForumPostClient.SUCCESS;
    payload: TForumPostApiEntity;
  }
  | {
    type: GetForumPostClient.FAILED;
    payload: any;
  }

export enum GetForumPostsClientPage {
  REQUEST = "GET_FORUM_POSTS_CLIENT_PAGE_REQUEST",
  SUCCESS = "GET_FORUM_POSTS_CLIENT_PAGE_SUCCESS",
  FAILED = "GET_FORUM_POSTS_CLIENT_PAGE_FAILED",
}
export type TGetForumPostsClientPageAction =
  | {
    type: GetForumPostsClientPage.REQUEST;
  }
  | {
    type: GetForumPostsClientPage.SUCCESS;
    payload: TApiPaginatorEntity<TForumPostApiEntity>;
  }
  | {
    type: GetForumPostsClientPage.FAILED;
    payload: any;
  }

export enum RequestClientForumAccess {
  REQUEST = "REQUEST_CLIENT_FORUM_ACCESS_REQUEST",
  SUCCESS = "REQUEST_CLIENT_FORUM_ACCESS_SUCCESS",
  FAILED = "REQUEST_CLIENT_FORUM_ACCESS_FAILED",
}
export type TRequestClientForumAccessAction =
  | {
    type: RequestClientForumAccess.REQUEST;
  }
  | {
    type: RequestClientForumAccess.SUCCESS;
    payload: any;
  }
  | {
    type: RequestClientForumAccess.FAILED;
    payload: any;
  }

export enum CreateClientForumPost {
  REQUEST = "CREATE_CLIENT_FORUM_POST_REQUEST",
  SUCCESS = "CREATE_CLIENT_FORUM_POST_SUCCESS",
  FAILED = "CREATE_CLIENT_FORUM_POST_FAILED",
}
export type TCreateClientForumPostAction =
  | {
    type: CreateClientForumPost.REQUEST;
  }
  | {
    type: CreateClientForumPost.SUCCESS;
    payload: any;
  }
  | {
    type: CreateClientForumPost.FAILED;
    payload: any;
  }

export enum CreateClientForumPostComment {
  REQUEST = "CREATE_CLIENT_FORUM_POST_COMMENT_REQUEST",
  SUCCESS = "CREATE_CLIENT_FORUM_POST_COMMENT_SUCCESS",
  FAILED = "CREATE_CLIENT_FORUM_POST_COMMENT_FAILED",
}
export type TCreateClientForumPostCommentAction =
  | {
    type: CreateClientForumPostComment.REQUEST;
  }
  | {
    type: CreateClientForumPostComment.SUCCESS;
    payload: any;
  }
  | {
    type: CreateClientForumPostComment.FAILED;
    payload: any;
  }

export enum DeleteForumPostClient {
  REQUEST = "DELETE_FORUM_POST_CLIENT_REQUEST",
  SUCCESS = "DELETE_FORUM_POST_CLIENT_SUCCESS",
  FAILED = "DELETE_FORUM_POST_CLIENT_FAILED",
}
export type TDeleteForumPostClientAction =
  | {
    type: DeleteForumPostClient.REQUEST;
  }
  | {
    type: DeleteForumPostClient.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteForumPostClient.FAILED;
    payload: any;
  }

export enum DeleteForumPostCommentClient {
  REQUEST = "DELETE_FORUM_POST_COMMENT_CLIENT_REQUEST",
  SUCCESS = "DELETE_FORUM_POST_COMMENT_CLIENT_SUCCESS",
  FAILED = "DELETE_FORUM_POST_COMMENT_CLIENT_FAILED",
}
export type TDeleteForumPostCommentClientAction =
  | {
    type: DeleteForumPostCommentClient.REQUEST;
  }
  | {
    type: DeleteForumPostCommentClient.SUCCESS;
    payload: any;
  }
  | {
    type: DeleteForumPostCommentClient.FAILED;
    payload: any;
  }

export enum HasAnyNewMessagesClientForum {
  SILENTREQUEST = "HAS_ANY_NEW_MESSAGES_CLIENT_FORUM_SILENTREQUEST",
  SILENTSUCCESS = "HAS_ANY_NEW_MESSAGES_CLIENT_FORUM_SILENTSUCCESS",
  SILENTFAILED = "HAS_ANY_NEW_MESSAGES_CLIENT_FORUM_SILENTFAILED",
};
export type THasAnyNewMessagesClientForumAction =
  | {
    type: HasAnyNewMessagesClientForum.SILENTREQUEST;
  }
  | {
    type: HasAnyNewMessagesClientForum.SILENTSUCCESS;
    payload: any;
  }
  | {
    type: HasAnyNewMessagesClientForum.SILENTFAILED;
    payload: any;
  }

export type TClientForumActions =
  | TAssignAccessToClientForumAction
  | TRejectClientForumAccessAction
  | TGetForumPostsClientAction
  | TGetForumPostsClientPageAction
  | TGetForumPostClientAction
  | TRequestClientForumAccessAction
  | TCreateClientForumPostAction
  | TCreateClientForumPostCommentAction
  | TDeleteForumPostClientAction
  | TDeleteForumPostCommentClientAction
  | THasAnyNewMessagesClientForumAction
  ;

