import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  DeepPartial,
  Store,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { TUserState } from "../types/User";
import { usersReducer } from "./reducers/UserReducer";
import { userSettingsReducer } from "./reducers/UserSettingsReducer";
import { clientForumReducer } from "./reducers/ClientForumReducer";
import { requestReducer } from "./reducers/RequestReducer";
import { ErrorHandler } from "./middlewares/ErrorHandler";
import { TAuthState } from "../types/Auth";
import { authReducer } from "./reducers/AuthReducer";
import { TErrorState } from "../types/Error";
import { errorReducer } from "./reducers/ErrorReducer";
import { TMenuState } from "../types/Menu";
import { menuReducer } from "./reducers/MenuReducer";
import { TContentState } from "../types/ContentPage";
import { contentReducer } from "./reducers/ContentReducer";
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { TUserSettingsState } from "../types/UserSettings";
import { TClientForumState } from "../types/ClientForum";
import { TRelativeForumState } from "../types/RelativeForum";
import { relativeForumReducer } from "./reducers/RelativeForumReducer";

export interface IAppState {
  appState: any;
  authState: TAuthState;
  contentState: TContentState;
  errorState: TErrorState;
  menuState: TMenuState;
  userState: TUserState;
  userSettingsState: TUserSettingsState;

  clientForumState: TClientForumState;
  relativeForumState: TRelativeForumState;
}

const initialAppState: DeepPartial<IAppState> = {
  appState: {},
  authState: undefined,
  contentState: undefined,
  errorState: undefined,
  menuState: undefined,
  userState: undefined,
  userSettingsState: undefined,

  clientForumState: undefined,
  relativeForumState: undefined,
};

// function configureStore(): Store<IAppState, AnyAction> {
//   return createStore(
//     rootReducer,
//     initialAppState,
//     composeWithDevTools(applyMiddleware(thunk, ErrorHandler))
//   );
// }

export const store = configureStore({
  reducer: {
    appState: requestReducer,
    authState: authReducer,
    contentState: contentReducer,
    errorState: errorReducer,
    menuState: menuReducer,
    userState: usersReducer,
    userSettingsState: userSettingsReducer,

    clientForumState: clientForumReducer,
    relativeForumState: relativeForumReducer,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;