import {
  CreateClientForumPost,
  CreateClientForumPostComment,
  DeleteForumPostClient,
  DeleteForumPostCommentClient,
  GetForumPostClient,
  GetForumPostsClient,
  GetForumPostsClientPage,
  HasAnyNewMessagesClientForum,
  RequestClientForumAccess,
  TCreateClientForumPostAction,
  TCreateClientForumPostCommentAction,
  TDeleteForumPostClientAction,
  TDeleteForumPostCommentClientAction,
  TGetForumPostClientAction,
  TGetForumPostsClientAction,
  TGetForumPostsClientPageAction,
  THasAnyNewMessagesClientForumAction,
  TRequestClientForumAccessAction,
} from "../../types/ClientForum";
import { apiCall, silentApiCall } from "./BaseAction";

// Forum
export const getForumPosts_Client = () => {
  return apiCall<TGetForumPostsClientAction>(
    GetForumPostsClient,
    "GET",
    "/forum/posts-client",
    true
  );
};
export const getForumPost_Client = (id: string) => {
  return apiCall<TGetForumPostClientAction>(
    GetForumPostClient,
    "GET",
    "/forum/posts-client/" + id,
    true
  );
};
export const getForumPosts_Client_Page = (pageUrl: string) => {
  return apiCall<TGetForumPostsClientPageAction>(
    GetForumPostsClientPage,
    "GET",
    pageUrl,
    true
  );
};
export type TRequestClientForumAccessRequest = {
  treater_id: string;
  message: string;
}
export const requestClientForumAccess = (data: TRequestClientForumAccessRequest) => {
  return apiCall<TRequestClientForumAccessAction>(
    RequestClientForumAccess,
    "POST",
    "/forum/request-access",
    true,
    data
  );
};
export type TCreateClientForumPostRequest = {
  title: string;
  content: string;
};
export const createClientForumPost = (data: TCreateClientForumPostRequest) => {
  return apiCall<TCreateClientForumPostAction>(
    CreateClientForumPost,
    "POST",
    "/forum/posts-client",
    true,
    data
  );
};
export type TCreateClientForumPostCommentRequest = {
  post_id: string;
  comment: string;
};
export const createClientForumPostComment = (data: TCreateClientForumPostCommentRequest) => {
  return apiCall<TCreateClientForumPostCommentAction>(
    CreateClientForumPostComment,
    "POST",
    "/forum/comments-client",
    true,
    data
  );
};
export type TDeleteForumPostClientRequest = {
  post_id: number;
};
export const deleteForumPost_Client = (data: TDeleteForumPostClientRequest) => {
  return apiCall<TDeleteForumPostClientAction>(
    DeleteForumPostClient,
    "DELETE",
    "/forum/posts-client",
    true,
    data
  );
};
export type TDeleteForumPostCommentClientRequest = {
  comment_id: number;
}
export const deleteForumPostComment_Client = (data: TDeleteForumPostCommentClientRequest) => {
  return apiCall<TDeleteForumPostCommentClientAction>(
    DeleteForumPostCommentClient,
    "DELETE",
    "/forum/comments-client",
    true,
    data
  );
};
export const hasAnyNewMessagesClientForum = () => {
  return silentApiCall<THasAnyNewMessagesClientForumAction>(
    HasAnyNewMessagesClientForum,
    "GET",
    "/forum/get-new-client",
    true
  );
};
// \ Forum
