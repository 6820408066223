export interface TApiUserSettingsEntity {
  user_id: number;
  setting_name: string;
  setting_value: number;
};

export interface TUserSettingsState {
  readonly loading?: boolean;
  readonly userSettings?: Array<TApiUserSettingsEntity>;
};

export const initialUserState: TUserSettingsState = {
  loading: undefined,
  userSettings: undefined,
};

export enum GetUserSettings {
  REQUEST = "GET_USER_SETTINGS_REQUEST",
  SUCCESS = "GET_USER_SETTINGS_SUCCESS",
  FAILED = "GET_USER_SETTINGS_FAILED"
};
export type TGetUserSettingsAction =
  | {
    type: GetUserSettings.REQUEST;
  }
  | {
    type: GetUserSettings.SUCCESS;
    payload: Array<TApiUserSettingsEntity>;
  }
  | {
    type: GetUserSettings.FAILED;
    payload: any;
  };

export enum GetUserSettingsByUserId {
  REQUEST = "GET_USER_SETTINGS_BY_USER_ID_REQUEST",
  SUCCESS = "GET_USER_SETTINGS_BY_USER_ID_SUCCESS",
  FAILED = "GET_USER_SETTINGS_BY_USER_ID_FAILED",
};
export type TGetUserSettingsByUserIdAction =
  | {
    type: GetUserSettingsByUserId.REQUEST;
  }
  | {
    type: GetUserSettingsByUserId.SUCCESS;
    payload: Array<TApiUserSettingsEntity>;
  }
  | {
    type: GetUserSettingsByUserId.FAILED;
    payload: any;
  };

export enum AddOrUpdateUserSetting {
  REQUEST = "ADD_OR_UPDATE_USER_SETTING_REQUEST",
  SUCCESS = "ADD_OR_UPDATE_USER_SETTING_SUCCESS",
  FAILED = "ADD_OR_UPDATE_USER_SETTING_FAILED"
};
export type TAddOrUpdateUserSettingAction =
  | {
    type: AddOrUpdateUserSetting.REQUEST;
  }
  | {
    type: AddOrUpdateUserSetting.SUCCESS;
    payload: any;
  }
  | {
    type: AddOrUpdateUserSetting.FAILED;
    payload: any;
  };

export enum AddOrUpdateUserSettings {
  REQUEST = "ADD_OR_UPDATE_USER_SETTINGS_REQUEST",
  SUCCESS = "ADD_OR_UPDATE_USER_SETTINGS_SUCCESS",
  FAILED = "ADD_OR_UPDATE_USER_SETTINGS_FAILED",
}
export type TAddOrUpdateUserSettingsAction =
  | {
    type: AddOrUpdateUserSettings.REQUEST;
  }
  | {
    type: AddOrUpdateUserSettings.SUCCESS;
    payload: any;
  }
  | {
    type: AddOrUpdateUserSettings.FAILED;
    payload: any;
  };

export type TUserSettingsActions =
  | TGetUserSettingsAction
  | TAddOrUpdateUserSettingAction
  | TAddOrUpdateUserSettingsAction
  | TGetUserSettingsByUserIdAction
  ;
