import { AxiosResponse, AxiosError } from "axios";
import { TApiErrorEntity } from "./Error";

export interface TApiAuthEntity {
  token: string;
}

export interface TAuthState {
  readonly token?: string;
}

export const initialAuthState: TAuthState = {
  token: undefined,
};

export enum LoginUser {
  REQUEST = "LOGIN_USER_REQUEST",
  SUCCESS = "LOGIN_USER_SUCCESS",
  FAILED = "LOGIN_USER_FAILED",
}

export type TLoginUserAction =
  | {
      type: LoginUser.REQUEST;
    }
  | {
      type: LoginUser.SUCCESS;
      payload: { token: string };
    }
  | {
      type: LoginUser.FAILED;
      payload: { token: string };
    };

export enum ForgotPassword {
  REQUEST = "FORGOT_PASSWORD_REQUEST",
  SUCCESS = "FORGOT_PASSWORD_SUCCESS",
  FAILED = "FORGOT_PASSWORD_FAILED",
}

export type TForgotPasswordAction =
  | {
      type: ForgotPassword.REQUEST;
    }
  | {
      type: ForgotPassword.SUCCESS;
      payload: any;
    }
  | {
      type: ForgotPassword.FAILED;
      payload: any;
    };

export enum ResetPassword {
  REQUEST = "RESET_PASSWORD_REQUEST",
  SUCCESS = "RESET_PASSWORD_SUCCESS",
  FAILED = "RESET_PASSWORD_FAILED",
}

export type TResetPasswordAction =
  | {
      type: ResetPassword.REQUEST;
    }
  | {
      type: ResetPassword.SUCCESS;
      payload: any;
    }
  | {
      type: ResetPassword.FAILED;
      payload: any;
    };

export enum RegisterUser {
  REQUEST = "REGISTER_USER_REQUEST",
  SUCCESS = "REGISTER_USER_SUCCESS",
  FAILED = "REGISTER_USER_FAILED",
}

export type TRegisterUserAction =
  | {
      type: RegisterUser.REQUEST;
    }
  | {
      type: RegisterUser.SUCCESS;
      payload: TApiAuthEntity;
    }
  | {
      type: RegisterUser.FAILED;
      payload: AxiosError<{ errors: Array<TApiErrorEntity> }>;
    };

export type TAuthActions =
  | TLoginUserAction
  | TForgotPasswordAction
  | TRegisterUserAction
  | TResetPasswordAction;
