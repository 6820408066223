import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useDialogStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      deleteButton_topBar: {
        position: "absolute",
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      content: {
        margin: theme.spacing(3, 0),
        whiteSpace: "pre-line",
        "& img, & iframe": {
          width: "100%",
          height: "auto",
          borderRadius: "10px",
        },
      },
      moodContent: {
        textAlign: "center",
        width: "100%",
      },
      moodTitle: {
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#000",
        margin: theme.spacing(3, 0),
      },
      moodList: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        marginBottom: "10px",
      },
      moodMessage: {
        width: "100%",
        maxHeight: "125px",
        height: "125px",
        marginBottom: "10px",
      },
      selectedMood: {
        border: "1px solid red",
      },
      myNotesTextArea: {
        width: "100%",
        resize: "none",
      },
      myNotesText: {
        fontSize: "20px",
        marginBottom: "3px",
        marginTop: "-15px",
      },
      moodBtnSelected: {
        background: "lightgray",

        "&:hover": {
          background: "lightgray",
        },
      },
      gamblingUrgesList: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "10px",
      },
      urgeSelectBtnText: {
        fontSize: "1.5rem",
      },
      icon: {
        width: "32px",
        minWidth: "32px",
        maxWidth: "32px",
        height: "32px",
        minHeight: "32px",
        maxHeight: "32px",
      },
      datePickerClearButton: {
        height: "45px",
        width: "45px",
      },
      datePickerClearButton_img: {
        height: "30px",
        width: "30px",
      },
    }),
  { index: 1 }
);
