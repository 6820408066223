import * as React from "react";
import { Button } from "@material-ui/core";
import { useNavbarStyles } from "../Navbar.styles";
import { MenuType, TApiMenuEntity } from "../../../types/Menu";
import { setSubMenuOpen } from "../../../store/actions/MenuActions";
import { useAppDispatch } from "../../../store/Store";

interface IHolderItemProps {
  closeMenuOnClick?: () => void;
  icon: any;
  title: string;
  subMenu?: Array<TApiMenuEntity>;
}

const HolderItem: React.FC<IHolderItemProps> = (props): JSX.Element => {
  const classes = useNavbarStyles({});
  const dispatch = useAppDispatch();
  const { subMenu, closeMenuOnClick, ...rest } = props;

  const openSubMenu = async () => {
    await dispatch(setSubMenuOpen(subMenu || [], MenuType.MAIN));
  };

  return (
    <Button {...rest} className={classes.menuItem} onClick={openSubMenu}>
      {props.title}
      <img src={props.icon} alt="arrow" />
    </Button>
  );
};

export default HolderItem;
