import moment from "moment";
import { TTreaterApiEntity, TUserState } from "../types/User";

// Authorization
export const getAccessToken = () => {
  return localStorage.getItem("token");
};
export const removeAuthInfoFromLocalStorage = () => {
  localStorage.removeItem("token");
};
export const setAccessToken = (token: string) => {
  localStorage.setItem("token", token);
};
// \Authorization



// Last Login
export const getLastLoginDate = () => {
  const date = localStorage.getItem("lastLogin");

  return JSON.parse(date as string);
};
export const setLastLogin = () => {
  const now = new Date();

  return localStorage.setItem("lastLogin", JSON.stringify(moment(now)));
};
// \Last Login



// Time | Date | Moment
export const dateOnly = (dateInput: Date) => {
  const date = new Date(dateInput);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
export const dateOnlyValueOf = (dateInput: Date) => {
  return dateOnly(dateInput).valueOf();
};
export const momentDateOnlyValueOf = (dateInput: Date) => {
  const date = new Date(dateInput);
  return moment(date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " 00:00:00").valueOf();
};
export const momentDateOnlyValueOfMoment = (momentInput: moment.Moment) => {
  return moment(momentInput.year() + "-" + momentInput.month() + "-" + momentInput.date() + " 00:00:00", 'YYYY-MM-DD HH:mm:ss').valueOf();
};
export const momentToDateStr = (input: moment.Moment) => {
  return input.year() + "-" + input.month() + "-" + input.date();
}
/**
 * Convert Date object to moment object formatted to date (YYYY-MM-DD)
 * @param date 
 * @returns 
 */
export const dateToMomentDate = (date: Date) => {
  return moment(moment(date).format('YYYY-MM-DD'));
};
export const momentToMomentDate = (_moment: moment.Moment) => {
  return moment(moment(_moment).format('YYYY-MM-DD'));
};
/**
 * Checks if provided date is at least 1 date ago
 * If null is provided true is returned.
 * @param date 
 * @returns 
 */
export const checkDateIs1DayAgo = (date: Date | null | undefined): boolean => {
  if (!date) {
    return true;
  }

  var a = dateToMomentDate(date);
  var b = dateToMomentDate(new Date());
  return a.isBefore(b, 'day');
};
export const checkMomentIs1DayAgo = (date: moment.Moment | null | undefined): boolean => {
  if (!date) {
    return true;
  }
  var b = moment(new Date());
  return date.isBefore(b, 'day');
};
export const dateToMoment = (date: Date) => {
  var dt = new Date(date);
  var yr = dt.getFullYear().toString();
  var mo = dt.getMonth().toString();
  var da = dt.getDate().toString();
  var hr = dt.getHours().toString();
  var mi = dt.getMinutes().toString();
  var ss = dt.getSeconds().toString();
  if (mo.length === 1) mo = "0" + mo;
  if (da.length === 1) da = "0" + da;
  if (hr.length === 1) hr = "0" + hr;
  if (mi.length === 1) mi = "0" + mi;
  if (ss.length === 1) ss = "0" + ss;
  const dateTimeStr = yr + "-" + mo + "-" + da + " " + hr + ":" + mi + ":" + ss;
  return moment(dateTimeStr, "YYYY-MM-DD HH:mm:ss");
};
export const dateToMoment_Utc = (date: Date) => {
  return moment.utc(date).local();
};
export const compareDates = (a: Date, b: Date): number => {
  return compareMoments(dateToMoment(a), dateToMoment(b));
};
export const compareDatesSameDay = (a: Date, b: Date): boolean => {
  return dateToMomentDate(a).isSame(dateToMomentDate(b));
};
export const compareMoments = (a: moment.Moment, b: moment.Moment): number => {
  return a.diff(b);
};
export const compareDatesAsStrings = (a: any, b: any): number => {
  const _a = new Date(a);
  const _aStr = _a.getFullYear().toString() + _a.getMonth().toString() + _a.getDate().toString();
  const _b = new Date(b);
  const _bStr = _b.getFullYear().toString() + _b.getMonth().toString() + _b.getDate().toString();
  return parseInt(_aStr) - parseInt(_bStr);  
};
export const compareMomentsAsDateStrings = (a: moment.Moment, b: moment.Moment): number => {
  return parseInt(a.format('YYYYMMDD')) - parseInt(b.format('YYYYMMDD'));
};
export const dateFormatWithDayofWeek = (date: Date, t: Function) => {
  const m = moment(date);
  return getDayOfWeekText(t, m.day()) + ', ' + m.date() + ' ' + getMonthText(t, m.month()) + ' ' + m.year();
};
/**
 * Format Date object to 'YYYY-MM-DD HH:mm:ss'
 */
export const dateFormat = (date: Date) => {
  return dateToMoment_Utc(date).format('YYYY-MM-DD HH:mm:ss');
};
export const dateFormatStr = (date: Date) => {
  var dt = new Date(date);
  var yr = dt.getFullYear().toString();
  var mo = dt.getMonth().toString();
  var da = dt.getDate().toString();
  var hr = dt.getHours().toString();
  var mi = dt.getMinutes().toString();
  var ss = dt.getSeconds().toString();
  if (mo.length === 1) mo = "0" + mo;
  if (da.length === 1) da = "0" + da;
  if (hr.length === 1) hr = "0" + hr;
  if (mi.length === 1) mi = "0" + mi;
  if (ss.length === 1) ss = "0" + ss;
  return yr + "-" + mo + "-" + da + " " + hr + ":" + mi + ":" + ss;
};
export const dateFormatStr_YYYYMMDDHHMM = (date: Date) => {
  var dt = new Date(date);
  var yr = dt.getFullYear().toString();
  var mo = dt.getMonth().toString();
  var da = dt.getDate().toString();
  var hr = dt.getHours().toString();
  var mi = dt.getMinutes().toString();
  if (mo.length === 1) mo = "0" + mo;
  if (da.length === 1) da = "0" + da;
  if (hr.length === 1) hr = "0" + hr;
  if (mi.length === 1) mi = "0" + mi;
  return yr + "-" + mo + "-" + da + " " + hr + ":" + mi;
};
export const dateFormatStr_YYYYMMMDD = (date: Date) => {
  var dt = new Date(date);
  var yr = dt.getFullYear().toString();
  var mo = dt.getMonth().toString();
  var da = dt.getDate().toString();
  if (mo.length === 1) mo = "0" + mo;
  if (da.length === 1) da = "0" + da;
  return yr + "-" + mo + "-" + da;
};
export const dateFormatStr_DDMMYYYY = (date: Date) => {
  var dt = new Date(date);
  var yr = dt.getFullYear().toString();
  var mo = dt.getMonth().toString();
  var da = dt.getDate().toString();
  if (mo.length === 1) mo = "0" + mo;
  if (da.length === 1) da = "0" + da;
  return da + "-" + mo + "-" + yr;
};
export const getWeeksSpannedByDateMonth = (date: Date) => {
  const m = moment(date);
  const day1 = moment(new Date(m.year(), m.month(), 1));

  var weeks = 1;
  var cnt: number;
  if (day1.day() === 0) {
    cnt = 6;
  }
  else {
    cnt = day1.day() - 1;
  }

  for (var i = 0; i < m.daysInMonth(); i++) {
    if (cnt === 6) {
      cnt = 0;
      continue;
    }

    if (cnt === 0 && i !== 0) {
      weeks = weeks + 1;
    }

    cnt = cnt + 1;
  }
  return weeks;
};
// \Time | Date | Moment



// Texts
export const getDayOfWeekText = (t: Function, dayOfWeek: number) => {
  if (dayOfWeek < 1 || dayOfWeek > 7) {
    return "N/A";
  }

  switch (dayOfWeek) {
    case 1: return t("misc.dates.day.of.week.moday");
    case 2: return t("misc.dates.day.of.week.tuesday");
    case 3: return t("misc.dates.day.of.week.wednesday");
    case 4: return t("misc.dates.day.of.week.thursday");
    case 5: return t("misc.dates.day.of.week.friday");
    case 6: return t("misc.dates.day.of.week.saturday");
    case 7: return t("misc.dates.day.of.week.sunday");
  }
};
export const getMonthText = (t: Function, month: number) => {
  if (month < 0 || month > 11) {
    return "N/A";
  }

  switch (month) {
    case 0: return t("misc.dates.month.january");
    case 1: return t("misc.dates.month.february");
    case 2: return t("misc.dates.month.march");
    case 3: return t("misc.dates.month.april");
    case 4: return t("misc.dates.month.may");
    case 5: return t("misc.dates.month.june");
    case 6: return t("misc.dates.month.july");
    case 7: return t("misc.dates.month.august");
    case 8: return t("misc.dates.month.september");
    case 9: return t("misc.dates.month.october");
    case 10: return t("misc.dates.month.november");
    case 11: return t("misc.dates.month.december");
  }
};
// \ Texts


// Treaters
export const getTreaterName = (treater: TTreaterApiEntity) => {
  var res: string;
  res = treater.user.name?? '';
  res = res + ' ' + treater.user.surname;
  return res;
}
// \Treaters



//User Roles
export const getUserRole = (role_id: number, userState?: TUserState) => 
{
  return userState?.userRoles?.find((role) => {
    return role.id === role_id;
  });
};
export const isAdminRole = (role_id: number, userState?: TUserState) => {
  const role = getUserRole(role_id, userState);
  if (!role) {
    return false;
  }

  return role.role === 'Admin' || role.role === 'admin';
};
// \User Roles




/**
 * @returns The hardcoded svg image content of material-ui Download icon
 */
export const getDownloadSvg = () => {
  return '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"/></g></svg>';
}