import { createTheme, ThemeOptions } from "@material-ui/core/styles";

export const Theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: "Graphik, sans-serif",
  },
  palette: {
    primary: {
      main: "#FF5241",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#E5F4D5",
      contrastText: "#000",
    },
    background: {
      default: "#f3f3f3",
    },
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        width: "100%",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(243, 243, 243, 0.9)",
      },
    },
    MuiTextField: {
      root: {
        width: "100%",
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: "5px",
      },
    },
    MuiButton: {
      root: {
        width: "100%",
        borderRadius: "5px",
      },
      label: {
        fontSize: "14px",
        lineHeight: "15px",
        textTransform: "none",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
  },
});
