import { TApiUserSettingsEntity } from "../types/User";

export class UserSettings {
    private static findSetting(setting_Name: string, userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return userSettings?.find((s) => {
            return s.setting_name === setting_Name;
        });
    }

    private static evalSettingValue(sett?: TApiUserSettingsEntity): boolean | undefined {
        if (!sett) {
            return undefined;
        }
        return sett.setting_value === 1;
    }

    // Has access to client forum
    public static getHasAccessToClientForum(userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return UserSettings.findSetting(UserSettingNames.Has_client_forum_access, userSettings);
    }
    public static getHasAccessToClientForum_Value(userSettings: Array<TApiUserSettingsEntity> | undefined): boolean | undefined {
        const sett = UserSettings.getHasAccessToClientForum(userSettings);
        return UserSettings.evalSettingValue(sett);
    }
    // \Has access to client forum


    // Permit relative to view gambling block
    public static getPermitRelativeToViewGamblingBlock(userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return UserSettings.findSetting(UserSettingNames.Permit_relative_to_view_gambling_block, userSettings);
    }
    public static getPermitRelativeToViewGamblingBlock_Value(userSettings: Array<TApiUserSettingsEntity> | undefined): boolean | undefined {
        const sett = UserSettings.getPermitRelativeToViewGamblingBlock(userSettings);
        return UserSettings.evalSettingValue(sett);
    }
    // \Permit relative to view gambling block


    // Show gambling block
    public static getShowGamblingBlock(userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return UserSettings.findSetting(UserSettingNames.Show_gambling_block, userSettings);
    }
    public static getShowGamblingBlock_Value(userSettings: Array<TApiUserSettingsEntity> | undefined): boolean | undefined {
        const sett = UserSettings.getShowGamblingBlock(userSettings);
        return UserSettings.evalSettingValue(sett);
    }
    // \Show gambling block


    // Show mood rating dialog
    public static getShowMoodRatingDialog(userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return UserSettings.findSetting(UserSettingNames.Show_mood_rating_dialog, userSettings);
    }
    public static getShowMoodRatingDialog_Value(userSettings: Array<TApiUserSettingsEntity> | undefined): boolean | undefined {
        const sett = UserSettings.getShowMoodRatingDialog(userSettings);
        return UserSettings.evalSettingValue(sett);
    }
    // \Show mood rating dialog


    // Show mood rating dialog daily note
    public static getShowMoodRatingDialogDailyNote(userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return UserSettings.findSetting(UserSettingNames.Show_mood_rating_dialog_daily_note, userSettings);
    }
    public static getShowMoodRatingDialogDailyNote_Value(userSettings: Array<TApiUserSettingsEntity> | undefined): boolean | undefined {
        const sett = UserSettings.getShowMoodRatingDialogDailyNote(userSettings);
        return UserSettings.evalSettingValue(sett);
    }
    // \Show mood rating dialog daily note


    // Show mood rating dialog gambling urge
    public static getShowMoodRatingDialogGamblingUrge(userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return UserSettings.findSetting(UserSettingNames.Show_mood_rating_dialog_gambling_urge, userSettings);
    }
    public static getShowMoodRatingDialogGamblingUrge_Value(userSettings: Array<TApiUserSettingsEntity> | undefined): boolean | undefined {
        const sett = UserSettings.getShowMoodRatingDialogGamblingUrge(userSettings);
        return UserSettings.evalSettingValue(sett);
    }
    // \Show mood rating dialog gambling urge


    // Show mood rating dialog mood rating
    public static getShowMoodRatingDialogMoodRating(userSettings: Array<TApiUserSettingsEntity> | undefined): TApiUserSettingsEntity | undefined {
        return UserSettings.findSetting(UserSettingNames.Show_mood_rating_dialog_mood_rating, userSettings);
    }
    public static getShowMoodRatingDialogMoodRating_Value(userSettings: Array<TApiUserSettingsEntity> | undefined): boolean | undefined {
        const sett = UserSettings.getShowMoodRatingDialogMoodRating(userSettings);
        return UserSettings.evalSettingValue(sett);
    }
    // \Show mood rating dialog mood rating
}

export class UserSettingNames {
    public static Has_client_forum_access: string = "has_client_forum_access";
    public static Permit_relative_to_view_gambling_block: string = "permit_relative_to_view_gambling_block";
    public static Show_gambling_block: string = "show_gambling_block";
    public static Show_mood_rating_dialog: string = "show_mood_rating_dialog";
    public static Show_mood_rating_dialog_daily_note: string = "show_mood_rating_dialog_daily_note";
    public static Show_mood_rating_dialog_gambling_urge: string = "show_mood_rating_dialog_gambling_urge";
    public static Show_mood_rating_dialog_mood_rating: string = "show_mood_rating_dialog_mood_rating";
}